import React, { Component } from 'react';
import $ from 'jquery';
import * as Icon from 'react-feather';
import Loading from '../partials/loading';
import Empty from '../partials/empty';
import Error from '../partials/error';
import Table from './table';
import axios from 'axios';

class fieldcheck extends Component {
    constructor(props) {
        super(props)

        let survey_id = localStorage.getItem('cloveDataSurvey_id');
        // let page = (localStorage.getItem('cloveDataPage') !== "" && localStorage.getItem('cloveDataPage') !== null) ? localStorage.getItem('cloveDataPage') : 1;
        let per_page = (localStorage.getItem('cloveDataPerPage') !== "" && localStorage.getItem('cloveDataPerPage') !== null) ? localStorage.getItem('cloveDataPerPage') : 25;
        let last_page = (localStorage.getItem('cloveDataLastPage') !== "" && localStorage.getItem('cloveDataLastPage') !== null) ? localStorage.getItem('cloveDataLastPage') : 1;

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            allItems: [],
            filter: [],
            isFilter: false,
            filterBy: "",
            survey_id: (survey_id !== undefined && survey_id !== null && survey_id !== "all") ? survey_id : "",
            page: 1,
            per_page: per_page,
            last_page: last_page,
            pagination: [],
            total_data: 0,
            bulkId: [],
            bulkProcess: false,
            keyword: ""
        };
    }

    handleBulk = () => {
        if ($('.bulk-items').is(":visible")) {
            this.setState({ bulkProcess: true });

            const id = this.state.bulkId;
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };

            axios.delete(api + "/answers/bulk", { data: { id: id }, headers: headers }).then(response => {
                id.map(value => ($('#tr-index-' + value).remove()));
                this.setState({ bulkId: [], bulkProcess: false })
                $('.bulk-items').css('display', 'none');
            }).catch(error => {
                this.setState({ bulkProcess: false });
                console.log(error.response);
                alert('Something is wrong!');
            });
        } else {
            this.setState({ bulkId: [] })
            $('.bulk-items').css('display', 'table-cell');
        }
    }

    handleBulkChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var bulkId = this.state.bulkId;
        var index = bulkId.indexOf(value);
        if (index > -1) {
            bulkId.splice(index, 1);
        }

        if (target.checked) { bulkId.push(value); }
        this.setState({ bulkId: bulkId });
        console.log(this.state.bulkId);
    }

    handleDownload = (survey_id) => {
        const api = localStorage.getItem('cloveApi');
        window.open(api + "/answers/export?survey_id[]=" + survey_id + '&answer_status_id=5', '_blank');
    }

    componentDidMount() {
        this.handleRefreshPage();

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept': 'application/json' };

        axios.get(api + "/surveys?is_archived=0&all=1", { headers })
        .then(response => {
            this.setState({ filter: response.data.data });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    handleStatus = (event, answer_id, target) => {
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept': 'application/json' };

        var value = event.target.value;
        var post = { "id": [answer_id], "answer_status_id": value };

        axios.patch(api + "/answers/bulk", post, { headers }).then(response => {
            $(target).remove();
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    handleSearch(event) {
        if (event.key === 'Enter') {
            this.setState({ isLoaded: false });

            let key = event.target.value;
            let keyArray = [];
            let items = this.state.allItems;
            let results;

            keyArray = key.split(" ");

            if (items.length > 0) {
                results = items.filter(x => {
                    let check;
                    for (let loop = 0; loop < keyArray.length; loop++) {
                        check = x.raw.toLowerCase().includes(keyArray[loop].toLowerCase());
                        if (!check) break;
                    }

                    return check;
                });
            }

            setTimeout(() => {
                this.setState({ items: results, isLoaded: true });
            }, 500);
        }
    }

    handleSearchServerSide = (event) => {
        if (event.key === 'Enter') {
            this.setState({ keyword: event.target.value, page: 1 });

            setTimeout(() => { this.handleRefreshPage(); }, 200);
        }
    }

    handleFilter = (event) => {
        this.setState({ survey_id: event.target.value, page: 1 });
        localStorage.setItem('cloveDataSurvey_id', event.target.value);

        setTimeout(() => { this.handleRefreshPage(); }, 200);
    }

    handlePagging = (event, page, type = "page") => {
        event.preventDefault();

        if (type === "per_page") { this.setState({ per_page: event.target.value, page: 1 }); }
        else { this.setState({ page: page }); }

        setTimeout(() => { this.handleRefreshPage(); }, 200);
    }

    handleRefreshPage = () => {
        this.setState({ isLoaded: false });

        let params = "";
        let page = this.state.page;
        let per_page = this.state.per_page;
        let survey_id = this.state.survey_id;
        let filter = (survey_id !== "all" && survey_id !== "") ? "&survey_id=" + survey_id : "";

        let search_filter = "";
        let keyword = this.state.keyword;
        keyword = (keyword !== "") ? keyword.split(" ") : [];

        if (keyword.length > 0) {
            keyword.forEach(key => {
                let dumb = key.split(":");
                if (dumb.length > 1) {
                    let dumbs = dumb[1].replace("-", " ");
                    search_filter += "&qs[" + dumb[0] + "]=" + dumbs;
                } else {
                    search_filter += "&q[]=" + key;
                }
            });

            params = search_filter;
        } else {
            params = "&page=" + page + "&per_page=" + per_page + "&sort_by=survey_id|desc" + filter;
        }

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept': 'application/json' };

        axios.get(api + "/answers?answer_status_id=5" + params, { headers }).then(response => {
            let last_page = (response.data.meta !== undefined) ? response.data.meta.last_page : 1;
            let total_data = (response.data.meta !== undefined) ? response.data.meta.total : response.data.data.length;
            let pagination = this.pageNumbers(parseInt(last_page), parseInt(page), this.handlePagging);
            this.setState({ items: response.data.data, allItems: response.data.data, isLoaded: true, page: page, per_page: per_page, last_page: last_page, pagination: pagination, total_data: total_data });

            localStorage.setItem('cloveDataPage', page);
            localStorage.setItem('cloveDataPerPage', per_page);
            localStorage.setItem('cloveDataLastPage', last_page);
        }).catch(error => {
            console.log(error);
            let message = error.response.data.message;
            alert(message);
        });
    }

    pageNumbers(count, current, handle) {
        var shownPages = 5;
        var result = [];

        if ((count - shownPages) !== 0 && current > (count - shownPages)) {

            if (count > 4) {
                result.push(
                    <li key={1}><a href="/#" onClick={(e) => handle(e, 1)}>{1}</a></li>,
                    <li key={`disable-${count}`} className="uk-disabled"><span>...</span></li>,
                    <li className={`uk-border-circle ${((count - 5) === current) && "uk-active"}`} key={count - 5}><a href="/#" onClick={(e) => handle(e, count - 5)}>{count - 5}</a></li>,
                    <li className={`uk-border-circle ${((count - 4) === current) && "uk-active"}`} key={count - 4}><a href="/#" onClick={(e) => handle(e, count - 4)}>{count - 4}</a></li>,
                );
            }
            if (count >= 4) {
                result.push(
                    <li className={`uk-border-circle ${((count - 3) === current) && "uk-active"}`} key={count - 3}><a href="/#" onClick={(e) => handle(e, count - 3)}>{count - 3}</a></li>,
                );
            }
            if (count >= 3) {
                result.push(
                    <li className={`uk-border-circle ${((count - 2) === current) && "uk-active"}`} key={count - 2}><a href="/#" onClick={(e) => handle(e, count - 2)}>{count - 2}</a></li>,
                );
            }
            if (count >= 2) {
                result.push(
                    <li className={`uk-border-circle ${((count - 1) === current) && "uk-active"}`} key={count - 1}><a href="/#" onClick={(e) => handle(e, count - 1)}>{count - 1}</a></li>,
                    <li className={`uk-border-circle ${(count === current) && "uk-active"}`} key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
                );
            }

        } else {

            if (current === 2 || current > 3) {
                result.push(<li key={1} className="uk-border-circle"><a href="/#" onClick={(e) => handle(e, 1)}>{1}</a></li>);
            }

            if (current > 4) {
                result.push(<li className="uk-border-circle uk-disabled" key={`disable`}><span>...</span></li>);
            }

            if (current > 4) {
                result.push(
                    <li className="uk-border-circle" key={current - 2}><a href="/#" onClick={(e) => handle(e, current - 2)}>{current - 2}</a></li>,
                    <li className="uk-border-circle" key={current - 1}><a href="/#" onClick={(e) => handle(e, current - 1)}>{current - 1}</a></li>,
                );
            }

            result.push(
                <li className="uk-border-circle uk-active" key={current}><a href="/#" onClick={(e) => handle(e, current)}>{current}</a></li>,
                <li className="uk-border-circle" key={current + 1}><a href="/#" onClick={(e) => handle(e, current + 1)}>{current + 1}</a></li>
            );

            if (current !== 4) {
                result.push(<li className="uk-border-circle" key={current + 2}><a href="/#" onClick={(e) => handle(e, current + 2)}>{current + 2}</a></li>);
            }

            if (current < 2) {
                result.push(
                    <li className="uk-border-circle" key={current + 3}><a href="/#" onClick={(e) => handle(e, current + 3)}>{current + 3}</a></li>,
                    <li className="uk-border-circle" key={current + 4}><a href="/#" onClick={(e) => handle(e, current + 4)}>{current + 4}</a></li>,
                );
            }

            if (current === 2) {
                result.push(<li className="uk-border-circle" key={current + 3}><a href="/#" onClick={(e) => handle(e, current + 3)}>{current + 3}</a></li>);
            }

            result.push(
                <li className="uk-border-circle uk-disabled" key={`disable-${current}`}><span>...</span></li>,
                <li className="uk-border-circle" key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
            );

        }
        return result;
    }

    render() {
        const { error, isLoaded, items, filter, survey_id, pagination, per_page, total_data, bulkProcess, page } = this.state;

        if (error) {
            return <Error message={error.message} />
        } else {
            return (
                <div>
                    <div className="uk-padding-small uk-margin-small-top">
                        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                            <div className="uk-width-auto">
                                <strong className="text-color-primary">Field Check ({total_data})</strong>
                            </div>
                            <div className="uk-width-expand">
                                <div className="uk-inline uk-width-1-1">
                                    <Icon.Search size={11} className="uk-form-icon small" />
                                    <input type="text" name="key" className="uk-input custom" placeholder="Search..." onKeyPress={(e) => this.handleSearchServerSide(e)} />
                                </div>
                            </div>
                            <div className="uk-width-medium uk-padding-small uk-padding-remove-vertical">
                                <select className="uk-select custom" name="survey_id" value={survey_id} onChange={this.handleFilter}>
                                    <option value="all">All jobs</option>
                                    {filter.map((option, index) => (
                                        <option key={`filter-survey-option-${index}`} value={option.id} >{option.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="uk-width-auto uk-padding-remove">
                                {
                                    (!bulkProcess) ?
                                        <button onClick={() => this.handleBulk()} type="button" className="uk-button uk-button-small uk-button-default">
                                            <Icon.Trash2 size={16} />
                                        </button>
                                        : <span uk-spinner="ratio: 1"></span>
                                }

                            </div>
                            {(survey_id !== "all" && survey_id !== "") &&
                                <div className="uk-width-auto uk-padding-remove border-left">
                                    <button onClick={() => this.handleDownload(survey_id)} uk-tooltip="title: Download; pos: left" type="button" className="uk-button uk-button-small uk-button-default"><Icon.Download size={16} /></button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="uk-position uk-position-fixed uk-position-small uk-position-bottom-right">
                        <div className="uk-grid-small border-bottom uk-background-default uk-flex-middle" uk-grid="true">
                            <div className="uk-width-auto uk-padding-remove">
                                <select className="uk-select custom" value={per_page} onChange={(e) => this.handlePagging(e, "", "per_page")}>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">250</option>
                                    <option value="500">500</option>
                                    <option value="all">All</option>
                                </select>
                            </div>
                            <div className="uk-width-expand">
                                <ul className="uk-pagination uk-margin-remove-bottom">{pagination}</ul>
                            </div>
                        </div>
                    </div>

                    {(isLoaded) ?
                        (items.length > 0) ? <Table items={items} survey_id={survey_id} per_page={per_page} page={page} handleBulkChange={this.handleBulkChange} /> : <Empty />
                        : <Loading />}
                </div>
            );
        }
    }
}

export default fieldcheck
