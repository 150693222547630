import React, { Component } from 'react'
import image from './empty.svg'

class empty extends Component {
    render() {
        return (
            <div className="empty-image">
                <img src={image} alt="Empty" />
                <p className="uk-text-lead">Sorry, no result found</p>
                <p className="uk-text-meta">What you searched was unfortunately not found or doesn't exist</p>
            </div>
        )
    }
}

export default empty
