import React, { Component } from 'react';
import './App.css';
import logo from './logoWhite.png'
import NavigationTop from './components/NavigationTop';
import NavigationSide from './components/NavigationSide';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/auth/index';
import Jobs from './components/jobs/index';
import Form from './components/jobs/form';
import Archived from './components/archived/index';
import Audiocheck from './components/data/audiocheck';
import Fieldcheck from './components/data/fieldcheck';
import QCcheck from './components/data/qccheck';
import Final from './components/data/final';
import Dropout from './components/data/dropout';
import Client from './components/client/Index';
import Respondent from './components/respondent/Index';
import Interviewer from './components/interviewer/Index';
import Settings from './components/settings/index';
import Roles from './components/settings/roles/index';
import Users from './components/settings/users/index';
import Profile from './components/settings/profile/index';
import Notification from './components/notification/index';
import axios from 'axios';
import Home from './components/home/index';


class App extends Component {
  constructor(props) {
    super(props)

    // localStorage.clear();
    const token = localStorage.getItem('cloveToken');
    if (token && token.length > 0) {
      this.state = {
        isLogin: true
      }
    } else {
      this.state = {
        isLogin: false
      }
    }
  }

  componentDidMount() {
    const { isLogin } = this.state;
    if (isLogin) {
      const token = localStorage.getItem('cloveToken');
      const api = localStorage.getItem('cloveApi');
      const headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };

      axios.get(api + "/clients", { headers }).then(response => {
        localStorage.setItem('cloveClientList', JSON.stringify(response.data.data));
      }).catch(error => {
        let message = error.response.data.message;
            alert(message);
      });
    }
  }

  render() {
    const { isLogin } = this.state;

    if (!isLogin) {
      return <Login />
    } else {
      const navToggleState = localStorage.getItem('cloveNav');

        return (
          <Router>
            <div>
              <NavigationTop logo={logo}/>
              <NavigationSide />
              <div className={`container ${navToggleState}`}>
                  <Switch>
                    <Route exact path="/"><Jobs/></Route>
                    <Route exact path="/notifications"><Notification/></Route>
                    <Route path="/active"><Jobs/></Route>
                    <Route path="/archived" ><Archived flag="archived" title="Archived Jobs" /></Route>
                    <Route path="/home" ><Home /></Route>

                    <Route path="/audio-check" ><Audiocheck flag="audio-check" status="2" title="Audio check" /></Route>
                    <Route path="/field-check" ><Fieldcheck flag="field-check" status="5" title="Field check" /></Route>
                    <Route path="/qc-check" ><QCcheck flag="qc-check" status="5" title="QC check" /></Route>
                    <Route path="/final" ><Final flag="final" status="4" title="Final data" /></Route>
                    <Route path="/drop-out" ><Dropout flag="Dropout" status="4" title="Dropout data" /></Route>

                    <Route path="/client" ><Client/></Route>
                    <Route path="/respondent" ><Respondent/></Route>
                    <Route path="/interviewer" ><Interviewer/></Route>

                    <Route exact path="/settings" ><Settings /></Route>
                    <Route path="/settings/roles" ><Roles /></Route>
                    <Route path="/settings/users" ><Users /></Route>
                    <Route path="/settings/profile" ><Profile /></Route>
                  </Switch>
              </div>
              <Form />
            </div>
          </Router>
        )
    }
  }
}

export default App;
