import React, { Component } from 'react';
import * as Icon from 'react-feather';
import { Route } from "react-router-dom";
import detail from './detail';
import List from './list';
import respondent from './respondent';
import Loading from '../partials/loading';
import Empty from '../partials/empty';
import None from '../partials/none';
import Error from '../partials/error';
import axios from 'axios';
import settings from './settings';
import $ from 'jquery';

class index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            lastList: "",
            activeList: "",
            error: null,
            isSort: true,
            isLoaded: false,
            isListLoaded: false,
            sortBy: "newest",
            items: [],
            bulkId: []
        }
    }

    handleBulk = () => {
        if ($('.bulk-items').is(":visible")){
            const id = this.state.bulkId;
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            axios.delete(api + "/surveys/bulk", { data: {id: id}, headers: headers })
                .then(response => {
                    id.map(value => ($('#list-' + value).remove()));
                    this.setState({ bulkId: [] })
                    $('.bulk-items').css('display', 'none');
                }).catch(error => {
                    let message = error.response.data.message;
            alert(message);
                });
        } else {
            this.setState({ bulkId: [] })
            console.log('bulk start!');
            $('.bulk-items').css('display', 'inline');
        }
    }

    handleBulkChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var bulkId = this.state.bulkId;
        var index = bulkId.indexOf(value);
        if (index > -1) {
            bulkId.splice(index, 1);
        }

        if (target.checked) { bulkId.push(value); }
        this.setState({ bulkId: bulkId });
        console.log(this.state.bulkId);
    }

    handleSort = (type) => {
        this.setState({ isSort: false, isListLoaded: false });
        let sort = "";
        if (type === "newest") sort = "?sort_by=created_at|desc";
        else if (type === "oldest") sort = "?sort_by=created_at|asc";
        else if (type === "az") sort = "?sort_by=name|asc";
        else if (type === "za") sort = "?sort_by=name|desc";

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/surveys?is_archived=0" + sort, { headers })
            .then(response => {
                this.setState({ items: response.data.data, sortBy: type, isSort: true, isListLoaded: true });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    componentDidMount(){
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/surveys?is_archived=0&all=1", { headers })
        .then(response => {
            this.setState({ items: response.data.data, isLoaded: true, isListLoaded: true });
            localStorage.setItem('cloveJobList', JSON.stringify(response.data.data));
        }).catch(error => {
            // let message = error.response.data.message;
            // alert(message);
            console.log(error);
            alert('Something is wrong');
        });
    }

    componentDidUpdate(){
        var list = document.getElementsByClassName("list");
        Array.prototype.forEach.call(list, function (list, index) {
            list.setAttribute("class", "list");
        });
    }

    render () {
        const { error, isLoaded, isListLoaded, items } = this.state;

        if (error) {
            return <Error message={error.message} />
        } else if (!isLoaded) {
            return <Loading />
        } else {
            if (items.length > 0) {
                return (

                    <div className="uk-grid-small uk-grid-match-height uk-viewport-height" uk-grid="true">
                        <div className="list-frame uk-width-1-3@m uk-viewport-height">
                            <div className="uk-grid-small list-header uk-flex-middle" uk-sticky="offset: 0" uk-grid="true">
                                <div className="uk-width-auto list-checkbox" hidden>
                                    <input className="uk-checkbox uk-border-circle" type="checkbox" name="permissions" />
                                </div>
                                <div className="uk-width-expand">
                                    <strong className="text-color-primary">Active jobs</strong>
                                </div>
                                <div className="uk-width-auto">
                                    <div className="uk-button-group rounded">
                                        <button uk-tooltip="title: Sort; pos: left" type="button" className="uk-button uk-button-small uk-border-rounded"><Icon.Filter size={16} /></button>
                                        <div uk-dropdown="mode: click; pos: left-top">
                                            {(this.state.isSort) ?
                                                <ul className="uk-nav uk-dropdown-nav">
                                                    <li><button onClick={() => this.handleSort('newest')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'newest' ? 'text-color-primary' : ''}`}>Newest</button></li>
                                                    <li className="uk-nav-divider"></li>
                                                    <li><button onClick={() => this.handleSort('oldest')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'oldest' ? 'text-color-primary' : ''}`}>Oldest</button></li>
                                                    <li className="uk-nav-divider"></li>
                                                    <li><button onClick={() => this.handleSort('az')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'az' ? 'text-color-primary' : ''}`}>A - Z</button></li>
                                                    <li className="uk-nav-divider"></li>
                                                    <li><button onClick={() => this.handleSort('za')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'za' ? 'text-color-primary' : ''}`}>Z - A</button></li>
                                                </ul>
                                                : <span className="uk-text-small">Loading...</span>}
                                        </div>
                                        {/* <button uk-tooltip="title: Archive; pos: left" type="button" className="uk-button uk-button-small">
                                            <Icon.Archive size={16} />
                                        </button> */}
                                        <button onClick={() => this.handleBulk()} uk-tooltip="title: Delete; pos: left" type="button" className="uk-button uk-button-small">
                                            <Icon.Trash2 size={16} />
                                        </button>
                                        <button type="button" uk-toggle="target: #modal-survey" className="uk-button uk-button-small uk-border-rounded uk-button-primary red">
                                            <Icon.Plus size={16} /> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ul className="uk-list uk-list-divider uk-margin-remove">
                                {(isListLoaded) ?
                                    <List items={items} handleBulkChange={this.handleBulkChange} />
                                :
                                    <Loading />
                                }
                            </ul>
                        </div>
                        <div className="detail-frame uk-width-2-3@m uk-viewport-height">
                            <Route exact path="/" component={None} />
                            <Route exact path="/active" component={None} />
                            <Route path="/active/detail/:id" component={detail} />
                            <Route path="/active/respondent/:id/:survey_id" component={respondent} />
                            <Route path="/active/settings/:id" component={settings} />
                        </div>
                    </div>
                )
            } else {
                return <Empty />
            }
        }
    }
}

export default index
