import React, { Component } from 'react'
import image from '../../partials/add.svg'
import axios from 'axios';

class edit extends Component {
    constructor(props) {
        super(props)

        this.state = this.props.profile;
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        const post = this.state;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.put(api + "/profile", post, { headers })
            .then(response => {
                localStorage.setItem('cloveProfile', JSON.stringify(response.data.data));
                window.location.reload(false);
            });

        event.preventDefault();
    }

    render() {
        return (
            <div id="modal-edit" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-medium uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                    <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                        <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                            <div className="uk-padding uk-padding-remove-bottom body-frame">
                                <div className="uk-margin">
                                    <img className="uk-width-1-2 uk-align-center" src={image} alt="Add" />
                                </div>
                                <p>
                                    <strong>Edit profile</strong><br/>
                                    Please fill in all required fields marked with (*)
                                </p>

                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-small">Name (*)</label>
                                    <input className="uk-input custom" placeholder="..." type="text" name="name" value={(this.state.name !== null) ? this.state.name : ""} onChange={this.handleChange} required />
                                </div>
                                {/* <div className="uk-margin">
                                    <label className="uk-form-label uk-text-small">Username</label>
                                    <input className="uk-input custom" placeholder="..." type="text" name="username" value={(this.state.username !== null) ? this.state.username : ""} onChange={this.handleChange} />
                                </div> */}
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-small">Email (*)</label>
                                    <input className="uk-input custom" placeholder="..." type="email" name="email" value={(this.state.email !== null) ? this.state.email : ""} onChange={this.handleChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer">
                            <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default edit
