import React, { Component } from 'react'
import image from '../partials/add.svg'
import axios from 'axios';

class form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            edit: "",
            name: "",
            address: "",
            postal_code: "",
            interviewer_name: "",
            phone: "",
            telephone: "",
            email: "",
            instagram: "",
            facebook: ""
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        const post = this.state;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/respondents", post, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        event.preventDefault();
    }

    render() {
        return (
            <div id="modal-add" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-xlarge uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                    <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                        <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                            <div className="uk-padding uk-padding-remove-bottom body-frame">

                                <div className="uk-margin">
                                    <img className="uk-width-1-2 uk-align-center" src={image} alt="Add" />
                                </div>
                                <p>
                                    <strong>New respondent</strong><br/>
                                    Please fill in all required fields marked with (*)
                                </p>
                                <div className="uk-grid-small uk-child-width-1-2 uk-margin-bottom" uk-grid="true">
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Name (*)</label>
                                            <input type="hidden" name="id" value="" />
                                            <input className="uk-input custom" placeholder="..." type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Address (*)</label>
                                            <textarea className="uk-textarea custom" rows="3" placeholder="..." type="text" name="address" value={this.state.address} onChange={this.handleChange} required></textarea>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Postal code</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="postal_code" value={this.state.postal_code} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Email (*)</label>
                                            <input className="uk-input custom" placeholder="..." type="email" name="email" value={this.state.email} onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Phone</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Telephone</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="telephone" value={this.state.telephone} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Instagram</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="instagram" value={this.state.instagram} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Facebook</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="facebook" value={this.state.facebook} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Interviewer</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="interviewer_name" value={this.state.interviewer_name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="uk-modal-footer">
                            <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default form
