import React, { Component } from 'react'
import Empty from '../partials/empty';
import axios from 'axios';
import { Fragment } from 'react';

const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

class clone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            client: [],
            file: null,
            survey_id: this.props.survey_id,
            clone: false
        };
    }

    componentDidMount() {
        let jobList = JSON.parse(localStorage.getItem('cloveJobList'));
        this.setState({ items: jobList });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    clone = (sourceID) => {
        this.setState({ clone: true });

        const { survey_id } = this.state;
        const post = { target_id: survey_id }
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/surveys/" + sourceID + '/clone', post, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { items, survey_id, clone } = this.state;
        return (
            <div>
                <div id="modal-codification-clone" className="uk-flex-top" uk-modal="true">
                    <div className="uk-modal-dialog uk-width-large uk-margin-auto-vertical">
                        {(clone === false) ?
                            <Fragment>
                                <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                <form className="uk-form-stacked">
                                    <div className="uk-modal-body no-header no-footer uk-padding-remove" uk-overflow-auto="true">
                                        <div className="uk-padding uk-padding-remove-bottom body-frame">
                                            <p>
                                                <strong>Clone</strong><br />
                                                Copy codification from existing jobs
                                            </p>
                                            <div className="list-frame uk-padding-remove empty-xframe">
                                                {(items.length > 0) ?
                                                    <div className="uk-margin-bottom">
                                                        {items.map((value, index) => (
                                                            (survey_id !== value.id.toString()) ?
                                                                <div key={`list-${value.id}`} className="button-frame">
                                                                    <div id={`list-${value.id}`}  className="border-top button" onClick={() => this.clone(value.id)}>
                                                                        <div className="uk-grid-small uk-flex-top" uk-grid="true">
                                                                            <div className="uk-width-auto uk-padding-remove">
                                                                                <span className={`initial-name bg-color-${(value.id.length > 1 ? value.id.substring(1, 3) : value.id)}`}>{toInitials(value.name)}</span>
                                                                            </div>
                                                                            <div className="uk-width-expand">
                                                                                <p>{value.name}</p>
                                                                                <p className="uk-text-truncate">{value.client_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : (items.length === 1) && <Empty key={`list-${value.id}`} />
                                                        ))}
                                                    </div>
                                                : <Empty /> }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Fragment>
                        :
                            <div className="uk-modal-body no-header no-footer uk-padding-remove">
                                <div className="uk-padding-remove body-frame">
                                    <p className="uk-text-center uk-margin-top">Cloning...</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default clone
