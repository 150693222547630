import React, { Component } from 'react'
import axios from 'axios';
import { Fragment } from 'react';

class importAnswer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            client: [],
            file: null,
            survey_id: this.props.survey_id,
            upload: false
        };
    }

    handleChange = (event) => {
        this.handleImport(event);
    }

    handleImport = (event) => {
        this.setState({ upload: true });

        const { survey_id } = this.state;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');

        const files = event.target.files
        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('survey_id', survey_id)

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/answers/import", formData, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        event.preventDefault();
    }

    render() {
        const { upload } = this.state;

        return (
            <div>
                <div id="modal-import" className="uk-flex-top" uk-modal="true">
                    <div className="uk-modal-dialog uk-width-medium uk-margin-auto-vertical">
                        {(upload === false) ?
                            <Fragment>
                                <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                <form className="uk-form-stacked" onSubmit={this.handleImport}>
                                    <div className="uk-modal-body no-header no-footer uk-padding-remove">
                                        <div className="uk-padding uk-padding-remove-bottom body-frame">
                                            <p>
                                                <strong>Import Respondents</strong><br />
                                                import raw data to be extracted and translated by codes
                                            </p>
                                            <div className="js-upload uk-margin-bottom uk-text-center">
                                                <div uk-form-custom="target: > * > span:first-child" className="uk-width-1-1">
                                                    <input type="file" name="file" accept="application/txt, .csv" onChange={this.handleChange} />
                                                    <span className="uk-button uk-button-primary red uk-border-rounded uk-button-small uk-width-1-1">browse file</span>
                                                </div>
                                                <p className="uk-text-small uk-margin-remove-bottom">select only csv or txt type file</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Fragment>
                        :
                            <div className="uk-modal-body no-header no-footer uk-padding-remove">
                                <div className="uk-padding-remove body-frame">
                                    <p className="uk-text-center uk-margin-top">Uploading...</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default importAnswer
