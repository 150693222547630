import React, { Component } from 'react'
import moment from 'moment';
import Loading from '../partials/loading';
import Empty from '../partials/empty';
import Error from '../partials/error';
import axios from 'axios';

const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

class index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            isSort: true,
            items: []
        }
    }

    componentDidMount() {
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/notifications?all=1", { headers }).then(response => {
            this.setState({ items: response.data.data, isLoaded: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { error, isLoaded, items } = this.state; console.log('timeline', items);

        if (error) {
            return <Error message={error.message} />
        } else if (!isLoaded) {
            return <Loading />
        } else {
            if (items.length > 0) {
                return (
                    <div className="uk-container uk-container-xsmall notification-frame">
                        <div className="uk-width-2-3@m">
                            <div className="detail-header">
                                <div>
                                    <strong className="text-color-primary">Timeline</strong><br/>
                                    <span className="uk-text-small">All important activities will be automatically posted here</span>
                                </div>
                            </div>
                            <div>
                                <ul className="uk-list uk-list-divider list-frame uk-background-default">
                                    {items.map((value) => (
                                        <li className="list">
                                            <div className="uk-grid-small uk-flex-top" uk-grid="true">
                                                <div className="uk-width-auto">
                                                    <span className={`initial-name bg-color-1`}>{toInitials(value.title)}</span>
                                                    {/* <span className={`initial-name bg-color-${(value.id.length > 1 ? value.id.substring(1, 3) : value.id)}`}>{toInitials(value.name)}</span> */}
                                                </div>
                                                <div className="uk-width-expand">
                                                    <span className="uk-float-right uk-text-small uk-text-right">
                                                        {/* {moment(value.created_at).format("dddd")},&nbsp; */}
                                                        {moment(value.created_at).format("D MMM")}<br />
                                                        {moment(value.created_at).format("YYYY")}
                                                    </span>
                                                    <p>{value.title}</p>
                                                </div>
                                            </div>
                                        </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="uk-container uk-container-xsmall notification-frame">
                        <div className="uk-width-2-3@m">
                            <div className="detail-header">
                                <div>
                                    <strong className="text-color-primary">Timeline</strong><br />
                                    <span className="uk-text-small">All important activities will be automatically posted here</span>
                                </div>
                            </div>
                            <div>
                                <Empty />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default index
