import React, { Component } from 'react'

class loading extends Component {
    render() {
        return (
            <div className="detail-header">
                <div className="empty-image">
                    <p className="uk-text-lead">Loading...</p>
                </div>
            </div>
        )
    }
}

export default loading
