import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class bar extends Component {
    constructor(props) {
        super(props)

        let summary = this.props.summary;

        this.state = {
            data: {
                labels: ['RAW', 'Audio check', 'QC check', 'Field check', 'Final', 'Drop out'],
                datasets: [
                    {
                        label: 'Show / Hide',
                        data: [
                            (summary.raw !== undefined) ? summary.raw : 0,
                            (summary.audio_check !== undefined) ? summary.audio_check : 0,
                            (summary.qc_check !== undefined) ? summary.qc_check : 0,
                            (summary.field_check !== undefined) ? summary.field_check : 0,
                            (summary.final !== undefined) ? summary.final : 0,
                            (summary.drop_out !== undefined) ? summary.drop_out : 0,
                        ],
                        backgroundColor:[
                            '#f0506e',
                            '#faa05a',
                            '#f0506e',
                            '#32d296',
                            '#1e87f0',
                            '#dddddd',
                        ],
                    }
                ]
            }
        }
        this.chartReference = React.createRef();
    }

    render() {
        return (
            <div>
                <p>{this.props.title}</p>
                <Bar data={this.state.data}
                    ref={this.chartReference}
                    options={{
                        maintainAspectRatio: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        legend: {
                            display: false
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                align: 'center',
                                anchor: 'center',
                                color: 'black'
                            }
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return tooltipItem.yLabel;
                                }
                            }
                        }

                    }} />
            </div>
        )
    }
}

export default bar
