import React, { Component, Fragment } from 'react'
import axios from 'axios';

class codification extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            client: [],
            code: '',
            value: '',
            sub_value: '',
            identifier: '',
            has_option: false,
            survey_id: this.props.survey_id,
            isSubmitting: false
        };
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmitting: true });
        const { code, value, sub_value, identifier, survey_id, has_option } = this.state;
        const post = { code: code, value: value, sub_value: sub_value, identifier: identifier, survey_id: survey_id, has_option: Boolean(has_option) }
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/codifications", post, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        event.preventDefault();
    }

    render() {
        const { isSubmitting } = this.state;

        return (
            <div id="modal-code" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-xlarge uk-margin-auto-vertical">
                    {(isSubmitting === false) ?
                        <Fragment>
                            <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                            <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                                <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                                    <div className="uk-padding uk-padding-remove-bottom body-frame">
                                        <p>
                                            <strong>New code</strong><br />
                                            Please fill in all required fields marked with (*)
                                        </p>
                                        <div className="uk-grid-small uk-child-width-1-2 uk-margin-bottom" uk-grid="true">
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Code (*)</label>
                                                    <input className="uk-input custom" placeholder="..." type="text" name="code" value={this.state.code} onChange={this.handleChange}  required/>
                                                </div>

                                            </div>
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Type (*)</label>
                                                    <select className="uk-select custom" name="has_option" value={this.state.has_option} onChange={this.handleChange} required>
                                                        <option value=" ">Free answer</option>
                                                        <option value="true">Multiple option</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Question (*)</label>
                                                    <textarea className="uk-textarea custom" rows="3" placeholder="..." type="text" name="value" value={this.state.value} onChange={this.handleChange} required></textarea>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Translated question</label>
                                                    <textarea className="uk-textarea custom" rows="3" placeholder="..." type="text" name="sub_value" value={this.state.sub_value} onChange={this.handleChange}></textarea>
                                                </div>

                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Identifier</label>
                                                    <select className="uk-select custom" name="identifier" value={this.state.identifier} onChange={this.handleChange}>
                                                        <option value="">-</option>
                                                        <option value="name">As Respondent name (*)</option>
                                                        <option value="interviewer_name">As Interviwer name (*)</option>
                                                        <option value="interviewer_id">As Interviwer ID</option>
                                                        <option value="identity_number">As Identity Number</option>
                                                        <option value="local_identity_number">As Local Identity Number (KTP)</option>
                                                        <option value="address">As Respondent Address</option>
                                                        <option value="postal_code">As Postal code</option>
                                                        <option value="telephone">As Telephone</option>
                                                        <option value="phone">As Phone</option>
                                                        <option value="email">As Email</option>
                                                        <option value="instagram">As Instagram</option>
                                                        <option value="facebook">As Facebook</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-modal-footer">
                                    <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                                </div>
                            </form>
                        </Fragment>
                     :
                        <div className="uk-modal-body no-header no-footer uk-padding-remove">
                                <div className="uk-padding-remove body-frame">
                                    <p className="uk-text-center uk-margin-top">Submitting...</p>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default codification
