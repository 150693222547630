import React, { Component } from 'react';
import { Redirect } from 'react-router'
import Loading from '../../partials/loading';
import Error from '../../partials/error';
import Edit from './edit';
import * as Icon from 'react-feather';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

class index extends Component {
    constructor(props) {
        super(props)

        const profile = JSON.parse(localStorage.getItem('cloveProfile'));
        this.state = {
            error: null,
            isRedirect: false,
            isLoaded: false,
            profile: profile,
            items: [],
        }
    }

    signout = () => {
        localStorage.clear()
        setTimeout(() => {
            document.location = "/";
        }, 100);
    }

    componentDidMount() {
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/profile-tokens", { headers }).then(response => {
            this.setState({ items: response.data.data, isLoaded: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { error, isLoaded, profile, items, isRedirect } = this.state;

        if (isRedirect) {
            return <Redirect to='/settings/profile' />;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isLoaded) {
                return <Loading />
            } else {
                return (
                    <div>
                        <div className="uk-grid-small uk-grid-match uk-flex-top uk-viewport-height" uk-grid="true">
                            <div className="uk-width-1-3@m uk-viewport-height">
                                <div className="detail-header border-bottom uk-margin-bottom">
                                    <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <Link to="/settings" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.ArrowLeft size={20} /></Link>
                                        </div>
                                        <div className="uk-width-expand uk-padding-remove-left">
                                            <strong className="text-color-primary">Profile</strong>
                                        </div>
                                        <div className="uk-width-auto">
                                            <div className="uk-button-group">
                                                <button type="button" uk-toggle="target: #modal-edit" uk-tooltip="title: Edit; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Edit3 size={16} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin-top uk-flex-top" uk-grid="true">
                                    <div className="uk-width-auto uk-text-center">
                                        <div className="uk-margin-bottom uk-text-center">
                                            <span className={`initial-name big bg-color-${(profile.id.length > 1 ? profile.id.substring(1, 3) : profile.id)}`}>{toInitials(profile.name)}</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-expand">
                                        <p>
                                            <strong>Name</strong><br />
                                            {profile.name}
                                        </p>
                                        <p>
                                            <strong>Username</strong><br />
                                            {profile.username}
                                        </p>
                                        <p>
                                            <strong>Email</strong><br />
                                            {profile.email}
                                        </p>
                                        <p>
                                            <strong>Created at</strong><br />
                                            {moment(profile.created_at).format("D MMM")} {moment(profile.created_at).format("YYYY")}
                                        </p>
                                        <hr/>
                                        <div className="uk-margin-bottom">
                                            <button onClick={() => this.signout()} type="button" className="uk-button uk-button-primary uk-button-small uk-border-pill">Sign Out</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="uk-width-2-3@m uk-viewport-height">
                                <div>
                                    <div className="detail-header">
                                        <p className="uk-margin-small-top"><strong>Activity logs</strong></p>
                                        <span className="uk-text-meta">recorded log of when you sign in and perform a token update</span>
                                    </div>
                                    <div className="uk-width-1-1@m uk-padding-small uk-padding-remove-left">
                                        <table className="uk-table uk-table-small uk-table-divider">
                                            <tbody>
                                                {items.map((value, index) => (
                                                    <tr key={`logs-${index}`}>
                                                        <td>Login</td>
                                                        <td><strong>{value.name}</strong></td>
                                                        <td className="uk-text-nowrap">
                                                            <span className="uk-text-bold">
                                                                {moment(value.created_at).format("D MMM")}
                                                                {moment(value.created_at).format("YYYY")}
                                                            </span><br />
                                                            <span className="uk-text-small">
                                                                {moment(value.created_at).format("LTS")}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Edit profile={profile} />
                    </div>
                )
            }
        }
    }
}

export default index
