import React, { Component } from 'react'
import * as Icon from 'react-feather';
import logo from './logo.png';
import logoRed from './logoRed.png';
import image from './image.svg';
import axios from 'axios';

class index extends Component {

    constructor(props) {
        super(props)

        this.state = {
            passwordShown: false,
            signin: false,
        }

        // localStorage.clear()
    }

    togglePasswordVisiblity = () => {
        this.setState({
            passwordShown: (this.state.passwordShown ? false : true)
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ signin: true });
        event.preventDefault();
        const api = "https://api-clove.idsolutions.id";
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/login", this.state, { headers }).then(response => {
            const token = response.data.data.token;
            var headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };

            axios.get(api + "/profile", { headers }).then(response => {
                localStorage.setItem('cloveApi', api);
                localStorage.setItem('cloveToken', token);
                localStorage.setItem('cloveProfile', JSON.stringify(response.data.data));

                axios.get(api + "/clients", { headers }).then(response => {
                    localStorage.setItem('cloveClientList', JSON.stringify(response.data.data));

                    let initPage = {
                        activePage: "",
                        lastPage: "",
                        menuState: "expanded",
                    }
                    sessionStorage.setItem('localState', JSON.stringify(initPage));
                    setTimeout(() => {
                        window.location.reload(false)
                    }, 500);
                }).catch(error => {
                    let str = JSON.stringify(error.response.data.errors).replace("{", "").replace("}", "").replace(",", "\r\n\r\n");
                    let message = error.response.data.message + "\r\n\r\n" + str;
                    alert(message);
                });
            }).catch(error => {
                let str = JSON.stringify(error.response.data.errors).replace("{", "").replace("}", "").replace(",", "\r\n\r\n");
                    let message = error.response.data.message + "\r\n\r\n" + str;
                    alert(message);
            });
        }).catch((error) => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { passwordShown, signin } = this.state;
        return (
            <div className="login-frame">
                <div className="uk-container uk-container-small">
                    <div className="login-form">
                        <button uk-toggle="target: #modal-info" type="button" className="uk-button uk-button-small uk-button-default uk-width-auto uk-position-absolute uk-position-small uk-position-top-right"><Icon.Info size={16} /></button>
                        <div className="login-header uk-padding">
                            <img src={logo} className="login-logo" alt="Logo clove" />
                        </div>
                        <div className="uk-grid-match" uk-grid="true">
                            <div className="uk-width-3-5 uk-position-relative">
                                <img className="images" src={image} alt="Survey" />
                            </div>
                            <div className="uk-width-2-5 uk-position-relative">
                                <div className="uk-margin-bottom">
                                    <h2 className="text-color-primary uk-text-light">Welcome back :)</h2>
                                    <p>Sign in and start managing your surveys data and more</p>
                                </div>
                                <form className="uk-width-1-1" onSubmit={this.handleSubmit}>
                                    <div className="uk-margin">
                                        <div className="uk-inline input uk-width-1-1">
                                            <span className="uk-form-icon"><Icon.User size={16} /></span>
                                            <input className="uk-input" type="text" placeholder="Username" name="username" value={this.state.value} onChange={this.handleChange} />
                                        </div>
                                        <div className="uk-inline input uk-width-1-1">
                                            <span className="uk-form-icon"><Icon.Lock size={16} /></span>
                                            <input className="uk-input" type={passwordShown ? "text" : "password"} placeholder="Password" name="password" value={this.state.value} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <p className="uk-align-right uk-button uk-button-link" onClick={this.togglePasswordVisiblity}><Icon.Eye size={14} /> <span className="uk-text-small">{passwordShown ? "Hide" : "Show"} characters</span></p>
                                    <div className="uk-margin-top">
                                        {
                                            (!signin) ?
                                                <button type="submit" className="uk-button uk-button-primary red uk-border-pill uk-width-1-1">Sign in</button>
                                            :
                                                <button type="button" className="uk-button uk-button-primary red uk-border-pill uk-width-1-1"><span uk-spinner="ratio: 1"></span></button>
                                        }

                                    </div>
                                    <p className="uk-text-small">2020 &copy; Clove research and Marketing Analytics</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-info" className="uk-flex-top" uk-modal="true">
                    <div className="uk-modal-dialog uk-width-large uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                        <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                            <div className="uk-modal-body no-header no-footer uk-padding-remove" uk-overflow-auto="">
                                <div className="uk-padding body-frame">
                                    <div uk-grid="true">
                                        <div className="uk-width-1-3">
                                            <img className="" src={logoRed} alt="Logo info" />
                                        </div>
                                        <div className="uk-width-2-3">
                                            <p>
                                                <strong className="text-color-primary">Clove research and Marketing Analytics</strong>
                                            </p>
                                            <p>
                                                <strong>Clove Survey Manager</strong><br />
                                                processing raw survey data into a data bank to be managed and filtered into clean data.
                                            </p>
                                            <p>
                                                Designed by <a href="https://idsolutions.id" rel="noopener noreferrer" target="_blank" className="uk-button uk-button-link">IDsolutions</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default index
