import React, { Component } from 'react'
import axios from 'axios';

class form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            email: "",
            username: "",
            password: "",
            password_confirmation: "",
            roles: [],
            role_id: ""
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        let post = this.state;

        if(post.password === post.password_confirmation){
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };

            let role_id = post.role_id;

            // let email = (post.email === "") ? "" :  ;

            post = { username: post.username, password: post.password, password_confirmation: post.password_confirmation, name: post.name, email: post.email }

            axios.post(api + "/users", post, { headers }).then(response => {
                let id = response.data.data.id;
                let rolepost = { user_id: id, roles: [role_id] }
                axios.post(api + "/user-roles", rolepost, { headers }).then(response => window.location.reload(false));
            }).catch(error => {
                let str = JSON.stringify(error.response.data.errors).replace("{", "").replace("}", "").replace(",", "\r\n\r\n");
                let message = error.response.data.message + "\r\n\r\n" + str;
                alert(message);
                // let message = error.response.data.message;
                // alert(message);
            });

        } else {
            alert('Password umatch!');
        }

        event.preventDefault();
    }

    componentDidMount(){
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.get(api + "/roles?all=1", { headers }).then(response => this.setState({ roles: response.data.data }));
    }

    render() {
        const {roles} = this.state;

        return (
            <div id="modal-add" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-xlarge uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                    <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                        <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                            <div className="uk-padding uk-padding-remove-bottom body-frame">
                                <p>
                                    <strong>New user</strong><br/>
                                    Please fill in all required fields marked with (*)
                                </p>
                                <div className="uk-grid-small uk-child-width-1-2 uk-margin-bottom" uk-grid="true">
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Name (*)</label>
                                            <input type="hidden" name="id" value="" />
                                            <input className="uk-input custom" placeholder="..." type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Email</label>
                                            <input className="uk-input custom" placeholder="..." type="email" name="email" value={this.state.email} onChange={this.handleChange} required />
                                        </div>
                                        <div>
                                            <label className="uk-form-label uk-text-small">Role (*)</label>
                                            <select className="uk-select custom" name="role_id" value={this.state.role_id} onChange={this.handleChange} required>
                                                <option>-</option>
                                                {roles.map((option, index) => (
                                                    <option key={`roles-option-${index}`} value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Username (*)</label>
                                            <input className="uk-input custom" placeholder="..." type="text" name="username" value={this.state.username} onChange={this.handleChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Password (*)</label>
                                            <input className="uk-input custom" placeholder="..." type="password" name="password" value={this.state.passwrod} onChange={this.handleChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-small">Retype password (*)</label>
                                            <input className="uk-input custom" placeholder="..." type="password" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer">
                            <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default form
