import React, { Component, Fragment } from 'react'
import $ from 'jquery';
import * as Icon from 'react-feather';
import axios from 'axios';

class table extends Component {
    constructor(props) {
        super(props)

        this.state = {
            items: this.props.items,
            allItems: this.props.items,
            survey_id: this.props.survey_id,
            per_page: this.props.per_page,
            page: this.props.page,
            revision: "",
            options: [],
            optionsText: [],
            notes: null,
            clarifications: null,
            notesSubmit: false,
            columnFilter: [],
            isColumnFilterLoaded: false,
        }
    }

    handleClickColumnFilter = (codification) => {
        this.setState({ isColumnFilterLoaded: false });
        // console.log('filter', codification);
        // console.log(this.state.allItems);
        let allItems = this.state.allItems;
        let filterItems = this.state.columnFilter;
        filterItems[codification] = allItems.map((item) => { return { value: item.id, label: codification + '-' + item.id } });
        console.log(filterItems);

        setTimeout(() => {
            this.setState({ isColumnFilterLoaded: true, columnFilter: filterItems });
        }, 1000);
    }

    handleClickOption = (data, dataText) => {
        this.setState({
            options: data ,
            optionsText: dataText
        });
    }

    handleChangeOption = (event) => {
        const target = event.target;
        var id = target.id;
        var value = target.value;

        var options = this.state.options;
        var optionsText = this.state.optionsText;

        var index = options.indexOf(id);
        if (index > -1) {
            options.splice(index, 1);
            optionsText.splice(index, 1);
        }

        if (target.checked) {
            options.push(id);
            optionsText.push(value);
        }
        this.setState({ options: options, optionsText: optionsText });

        console.log('options', options, optionsText);
    }

    handleClick= (event) => {
        // console.log('start', this.state.revision);
        this.setState({ revision: event.target.value });
        // console.log('update', event);
    }

    handleChange = (event) => {
        let el = event.target;
        this.setState({ revision: el.innerText });
        // console.log('change', this.state.revision);
    }

    pin = (event, routeID, answer_id, code) => {
        const post = { codification_id: code};
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        if ($("#" + routeID).hasClass('edit') === false) {

            axios.patch(api + "/answers/" + answer_id +"/invalid", post, { headers })
                .then(response => console.log('pin', response));

            let el = event.target;
            if (el.nodeName === "SPAN") {
                el.setAttribute('contenteditable', 'true');
            }

            $("#" + routeID).addClass('bg-color-z edit');
        }
    }

    unpin = (event, routeID, answer_id, code) => {
        if (event.key === 'Enter'){
            event.preventDefault();
            let itemsRef = this.state.items;
            let allItemsRef = this.state.allItems;
            let el = event.target;
            let value = el.innerText;
            el.innerText = "Submitting...";
            el.removeAttribute('contenteditable');
            const post = { codification_id: code, value: value };
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };

            console.log('unpin proses', answer_id, post);

            axios.patch(api + "/answers/" + answer_id +"/revision", post, { headers }).then(response => {
                let dataRef = response.data.data;

                itemsRef.find(item => item.id === dataRef.id).raw = dataRef.raw;
                allItemsRef.find(item => item.id === dataRef.id).raw = dataRef.raw;

                itemsRef.find(item => item.id === dataRef.id).decoded_data = dataRef.decoded_data;
                allItemsRef.find(item => item.id === dataRef.id).decoded_data = dataRef.decoded_data;

                this.setState({
                    items: Object.values(itemsRef),
                    allItems: Object.values(allItemsRef)
                });

                el.innerText = value;
                el.setAttribute('contenteditable', 'true')
                $('#' + routeID).removeClass('bg-color-z edit');
            });
        }
    }

    unpinOptions = (routeID, answer_id, code) => {
        $('#' + routeID + " .button-options").html("Submitting...");
        let optionText = this.state.optionsText;
        const post = { codification_id: code, value: this.state.options.join(';') };
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.patch(api + "/answers/" + answer_id +"/revision", post, { headers }).then(response => {
            let html = (optionText.length > 1) ? optionText.slice(0, -1).join(', ') + ', ' + optionText.slice(-1) : optionText[0];
            $('#' + routeID + " .button-options").html(html);
            $('#' + routeID).removeClass('bg-color-z edit');
        }).catch(error => {
            $('#' + routeID + " .button-options").html("(must contain at least 1)");
        });
    }

    handleArchive = (answer_id, type) => {
        const post = {};
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        if (type === "respondent") {
            axios.post(api + "/answers/" + answer_id + "/archive-respondent", post, { headers }).then(response => {
                alert('Respondent data has been successfully achieved!');
            }).catch(e => {
                alert('archive error!');
            });
        }

        if (type === "interviewer"){
            axios.post(api + "/answers/" + answer_id + "/archive-interviewer", post, { headers }).then(response => {
                alert('Interviewer data has been successfully achieved!');
            }).catch(e => {
                alert('archive error!');
            });
        };
    }

    handleDelete = (routeID, answer_id) => {
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let html_ = $("#" + routeID + " td:nth-child(5)").html();
        $("#" + routeID + " td:nth-child(5)").html('<span uk-spinner="ratio: 1"></span>');

        axios.delete(api + "/answers/ " + answer_id, { headers }).then(response => {
            $("#" + routeID).remove();
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
            $("#" + routeID + " td:nth-child(5)").html(html_);
        });
    }

    handleFolder = (event, answer_id) => {

        const target = event.target;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let html_ = $("#tr-index-" + answer_id + " td:nth-child(3)").html();
        $("#tr-index-" + answer_id + " td:nth-child(3)").html('<span uk-spinner="ratio: 1"></span>');

        let value = target.value;
        let items = this.state.items;
        let item = (items.filter(x => { return x.id === parseInt(answer_id)}))[0];
        let post = {
             answer_status_id: item.answer_status_id,
             survey_id: item.survey_id,
             investigation_id: item.investigation_id,
             raw: item.raw,
             data: item.data
        }

        post['answer_status_id'] = (value === "null") ? null: value;

        axios.put(api + "/answers/" + answer_id, post, { headers }).then(response => {
            $("#tr-index-" + answer_id).remove();
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
            $("#tr-index-" + answer_id + " td:nth-child(3)").html(html_);
        });
    }

    handleStatus = (event, answer_id) => {
        const target = event.target;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        $("#tr-index-" + answer_id + " td:nth-child(4)").prepend('<span id="x-status-loading" uk-spinner="ratio: 1"></span>');
        $("#tr-index-" + answer_id + " td:nth-child(4) .uk-link").css('display', 'none');
        $("#tr-index-" + answer_id + " td:nth-child(3) select").attr('disabled', 'disabled');

        let value = target.value;
        let items = this.state.items;
        let item = (items.filter(x => { return x.id === parseInt(answer_id)}))[0];
        let post = {
            answer_status_id: item.answer_status_id,
            survey_id: item.survey_id,
            investigation_id: item.investigation_id,
            raw: item.raw,
            data: item.data
        }

        post['investigation_id'] = (value === "null") ? null: value;
        item.investigation_id = (value === "null") ? null : value;


        axios.put(api + "/answers/" + answer_id, post, { headers }).then(response => {
            let dataRef = response.data.data;

            items.find(item => item.id === dataRef.id).investigation_id = dataRef.investigation_id;

            this.setState({
                items: Object.values(items)
            });

            $("#x-status-loading").remove();
            $("#tr-index-" + answer_id + " td:nth-child(4) .uk-link").css('display', 'initial');
            $("#tr-index-" + answer_id + " td:nth-child(3) select").removeAttr('disabled');
        }).catch(error => {
            $("#x-status-loading").remove();
            $("#tr-index-" + answer_id + " td:nth-child(4) .uk-link").css('display', 'initial');
            $("#tr-index-" + answer_id + " td:nth-child(3) select").removeAttr('disabled');
            let message = error.response.data.message;
            alert(message);
        });

    }

    handleChangeNotes = (event, type="default") => {
        if (type === "default"){ this.setState({ notes: event.target.value }); }
        else if (type === "clarifications") { this.setState({ clarifications: event.target.value }); }
    }

    handleEditNotes = (notes, type = "default") => {
        if (type === "default") { this.setState({ notes: notes }); }
        else if (type === "clarifications") { this.setState({ clarifications: notes }); }
    }

    handleNotes = (answer_id, type="default") => {
        this.setState({ notesSubmit: true });

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let itemsRef = this.state.items;
        let allItemsRef = this.state.allItems;

        let items = this.state.items;
        let item = (items.filter(x => { return x.id === parseInt(answer_id) }))[0];
        let post = [];
        let notes = "";
        if (type === "default") {
            notes = this.state.notes;
             post = {
                notes: notes,
                answer_status_id: item.answer_status_id,
                survey_id: item.survey_id,
                investigation_id: item.investigation_id,
                raw: item.raw,
                data: item.data
            }
        } else if (type === "clarifications") {
            notes = this.state.clarifications;
            post = {
                clarifications: notes,
                answer_status_id: item.answer_status_id,
                survey_id: item.survey_id,
                investigation_id: item.investigation_id,
                raw: item.raw,
                data: item.data
            }
        }

        axios.put(api + "/answers/" + answer_id, post, { headers }).then(response => {
            let dataRef = response.data.data;

            if (type === "default") {
                itemsRef.find(item => item.id === dataRef.id).notes = dataRef.notes;
                allItemsRef.find(item => item.id === dataRef.id).notes = dataRef.notes;
            } else if (type === "clarifications") {
                itemsRef.find(item => item.id === dataRef.id).clarifications = dataRef.clarifications;
                allItemsRef.find(item => item.id === dataRef.id).clarifications = dataRef.clarifications;
            }

            this.setState({
                items: Object.values(itemsRef),
                allItems: Object.values(allItemsRef),
                notes: null,
                clarifications: null,
                notesSubmit: false
            });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { items, notesSubmit, survey_id, columnFilter, isColumnFilterLoaded, page, per_page } = this.state;
        let headerState = 0;
        let jsx;
        let jsx0;

        return (
            <div className="table-frame uk-overflow-auto uk-padding-small uk-padding-remove-right">
                <table className="uk-table uk-table-small uk-table-middle">
                    <tbody>
                        {items.map((tr,index) => {
                          jsx = ""; jsx0 = "";
                          if (headerState !== tr.survey_id) {
                                if(headerState !== 0) {
                                    jsx0 = (
                                        <tr>
                                            <td className="uk-table-shrink"><span>&nbsp;</span></td>
                                            {tr.decoded_data.map((td, index) => (<td key={`empty-col-${index}`}>&nbsp;</td>))}
                                        </tr>
                                    )
                                }

                                jsx = (
                                    <Fragment>
                                        <tr>
                                            <td className="uk-table-shrink" colSpan="100">
                                                <span className="uk-text-truncate"><b>{tr.survey_name}</b></span>
                                            </td>
                                        </tr>
                                        <tr className="uk-background-muted head border-remove-top">
                                            <td className="uk-table-shrink bulk-items border-right">
                                                {/* <input className="uk-checkbox uk-border-circle" type="checkbox" /> */}
                                            </td>
                                            <td className="uk-table-shrink uk-text-center border-right">#</td>
                                            <td className="uk-table-shrink uk-text-center border-right"><span>FOLDER</span></td>
                                            {(tr.answer_status_id.toString() !== "4" && tr.answer_status_id.toString() !== "7") &&
                                                <td className="uk-table-shrink uk-text-center border-right">
                                                    <span>STATUS</span>
                                                    <div className="uk-padding-small" uk-dropdown="pos: bottom-left; mode: hover">
                                                        <span className="uk-float-right uk-text-bold uk-text-small">Descriptions</span>
                                                        <ul className="uk-list uk-list-divider uk-margin-remove-bottom">
                                                            <li className="uk-text-left">
                                                                <b>1. Data tes</b><br/>
                                                                Semua data yg masuk mode live tapi isinya data tes
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>2. Tidak ada rekaman</b><br/>
                                                                Ada proses tunggu max 2 hari setelah upload, data rekaman tidak muncul langsung di judge masuk DO
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>3. Tidak dapat diverifikasi</b><br/>
                                                                Responden tidak bisa dihubungi karena berbagai hal (telpon salah, alamat salah, dll)
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>4. Proses callback</b><br/>
                                                                Ada ketidaklengkapan informasi data di beberapa pertanyaan
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>5. Briefing ulang</b><br/>
                                                                Ada kesalahan dalam bertanya, memprobe dll
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>6. Koreksi data</b><br/>
                                                                Koreksi data-data demografi, quota panel dll karena kesalahan interviewer dalam mencatat datanya atau ketidaksesuaian antara data dan rekaman
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>7. Kesalahan lain-lain</b><br/>
                                                                Semua ketidaksesuaian data lain yang tidak bisa dikonfirmasikan
                                                            </li>
                                                            <li className="uk-text-left">
                                                                <b>8. Data OK</b><br/>
                                                                Telah memenuhi semua persyaratan QC
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            }
                                            <td className="uk-table-shrink uk-text-center border-right"><span>{(tr.answer_status_id.toString() !== "4") ? "DEL" : "ARCHIVE"}</span></td>
                                            <td className="uk-preserve-width uk-text-center border-right">NOTES</td>
                                            {tr.decoded_data.map((td, index) => (
                                                <td key={`td-head-${index}`} className="border-right uk-preserve-width uk-position-relative">
                                                    <div className="uk-inline">
                                                        <span className="button uk-margin-right">{td.codification.code}</span>
                                                        <div className="uk-padding-small" uk-dropdown="pos: bottom-left; mode: click">
                                                            <span className="uk-float-right uk-text-bold uk-text-small">{td.codification.code}</span>
                                                            <ul className="uk-list uk-list-divider uk-margin-remove-bottom">
                                                                <li className="uk-text-left">{td.codification.value}</li>
                                                                <li className="uk-text-left">{td.codification.sub_value}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {(tr.survey_id !== "all" && survey_id !== "") &&
                                                        <div className="uk-position-top-right">
                                                            <button type="button" onClick={() => this.handleClickColumnFilter(td.codification.code)} className="uk-button uk-button-small uk-button-default"><Icon.ChevronsDown size={16} /></button>
                                                            <div className="width-release uk-width-auto" uk-dropdown="pos: bottom-right; mode: click">
                                                                <span className="uk-float-right uk-text-bold uk-text-small">{td.codification.code}</span>
                                                                {(isColumnFilterLoaded) ?
                                                                <Fragment>
                                                                    <ul className="uk-list uk-list-divider uk-margin-remove-bottom">
                                                                        {(columnFilter[td.codification.code]) && columnFilter[td.codification.code].map((data, index) => (
                                                                            <li key={`columnFilter-${td.codification.code}-${index}`} className="uk-text-left">
                                                                                <label className="uk-text-nowrap"><input type="checkbox" className="uk-checkbox uk-border-circle uk-margin-small-right"
                                                                                    // onChange={this.handleChangeOption}
                                                                                    // id={data.code}
                                                                                    value={data.value}
                                                                                    // defaultChecked={(td.answers.find(answer => answer.code === data.code)) ? true : false}
                                                                                    defaultChecked={true}
                                                                                /> {data.label}</label>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    <div className="uk-margin-top">
                                                                        <button type="button" className="uk-button uk-button-small uk-button-primary uk-border-rounded uk-width-1-1">Apply</button>
                                                                    </div>
                                                                </Fragment>
                                                                : <div className="uk-display-block uk-text-center"><span className="uk-margin-small-top" uk-spinner="ratio: 1"></span></div> }
                                                            </div>
                                                        </div>
                                                    }
                                                </td>
                                            ))}
                                        </tr>
                                    </Fragment>
                                );
                                headerState = tr.survey_id;
                            }

                            return (
                                <Fragment key={`frag-row-${tr.id}`}>
                                    { (jsx0 !== "") && jsx0 }
                                    { (jsx !== "") && jsx }
                                    <tr id={`tr-index-${tr.id}`} className="body">
                                        <td className="bulk-items uk-text-center border-bottom border-right">
                                            <input className="uk-checkbox uk-border-circle" type="checkbox" name="bulkId" value={tr.id} onChange={this.props.handleBulkChange} />
                                        </td>
                                        <td className="uk-text-center border-bottom border-right">{((page-1) * per_page) + index+1}</td>
                                        <td className="uk-text-center border-right border-bottom">
                                            {(tr.answer_status_id.toString() !== "4") ?
                                                <div id={`statusSelect-frame-${tr.id}`} uk-form-custom="target: > * > span:first-child">
                                                    <select onChange={(e) => this.handleFolder(e, tr.id)} id-answer={tr.id} defaultValue={tr.answer_status_id} className="statusSelect">
                                                        <option value="2">Audio check</option>
                                                        <option value="6">QC check</option>
                                                        <option value="5">Field check</option>
                                                        <option value="4">Final</option>
                                                        <option value="7">Drop out</option>
                                                    </select>
                                                    <span className="uk-link">
                                                        <span className={`statusSelect-label uk-text-center uk-label ${(tr.answer_status_id === 2) ? `uk-label-warning` : ``} ${(tr.answer_status_id === 3) ? `uk-label-primary` : ``} ${(tr.answer_status_id === 4 || tr.answer_status_id === 7) ? `uk-label-muted` : ``} ${(tr.answer_status_id === 5) ? `uk-label-success` : ``} ${(tr.answer_status_id === 6) ? `uk-label-danger` : ``} uk-border-pill uk-text-capitalize`}></span>
                                                    </span>
                                                </div>
                                            : <span className={`uk-text-center uk-label uk-border-pill uk-text-capitalize`}>Final</span> }
                                        </td>
                                        {(tr.answer_status_id.toString() !== "4" && tr.answer_status_id.toString() !== "7") &&
                                            <td className="uk-text-center border-right border-bottom">
                                                <div id={`investigationSelect-frame-${tr.id}`} uk-form-custom="target: > * > span:first-child">
                                                    <select onChange={(e) => this.handleStatus(e, tr.id)} id-answer={tr.id} defaultValue={tr.investigation_id} className="investigationSelect">
                                                        <option value="null">None</option>
                                                        <option value="1">Data tes</option>
                                                        <option value="2">Tidak ada rekaman</option>
                                                        <option value="3">Tidak dapat diverifikasi</option>
                                                        <option value="4">Proses callback</option>
                                                        <option value="5">Briefing ulang</option>
                                                        <option value="6">Koreksi data</option>
                                                        <option value="7">Kesalahan lain-lain</option>
                                                        <option value="8">Data OK</option>
                                                    </select>
                                                    <span className="uk-link">
                                                        <span className={`investigationSelect-label uk-text-center uk-label uk-label-primary uk-border-pill uk-text-capitalize`}></span>
                                                    </span>
                                                </div>
                                            </td>
                                        }
                                        <td className="uk-text-center border-right border-bottom">
                                            {(tr.answer_status_id.toString() !== "4") ? <button type="button" onClick={() => this.handleDelete(`tr-index-${tr.id}`, tr.id)} uk-tooltip="title: Delete; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Trash2 size={16} /></button>
                                            :
                                                <div className="uk-button-group uk-preserve-width">
                                                    <button type="button" onClick={() => this.handleArchive(tr.id, "respondent")} uk-tooltip="title: Archive respondent; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Archive size={16} /></button>
                                                    <button type="button" onClick={() => this.handleArchive(tr.id, "interviewer")} uk-tooltip="title: Archive interviewer; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center border-left"><Icon.Archive size={16} /></button>
                                                </div>
                                            }
                                        </td>

                                        {/* NOTES-- */}
                                        <td className="uk-text-center border-right border-bottom">
                                            <div className="uk-button-group uk-preserve-width">
                                                <div className="uk-inline">
                                                    <button onClick={() => this.handleEditNotes(tr.notes)} className="uk-button uk-button-default uk-button-small uk-text-nowrap uk-text-left button-options" type="button">
                                                        {(tr.notes !== null) ? <Icon.AlignLeft size={16} /> : <Icon.Edit3 size={16} />}
                                                    </button>
                                                    <div uk-dropdown="mode: click" className="list-option-drop uk-width-large">
                                                        {(notesSubmit === false) ?
                                                            <Fragment>
                                                                <span className="uk-float-right uk-text-bold uk-text-small">Notes</span>
                                                                <textarea name="notes" className="uk-textarea custom uk-text-left" defaultValue={tr.notes} onChange={this.handleChangeNotes}></textarea>
                                                                <div className="uk-margin-top">
                                                                    <button onClick={() => this.handleNotes(tr.id)} type="button" className="uk-button uk-button-small uk-button-primary uk-border-rounded uk-width-1-1">Submit</button>
                                                                </div>
                                                            </Fragment>
                                                            : <span>Submitting...</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="uk-inline border-left">
                                                    <button onClick={() => this.handleEditNotes(tr.clarifications, "clarifications")} className="uk-button uk-button-default uk-button-small uk-text-nowrap uk-text-left button-options" type="button">
                                                        {(tr.clarifications !== null) ? <Icon.AlignLeft size={16} /> : <Icon.Edit3 size={16} />}
                                                    </button>
                                                    <div uk-dropdown="mode: click" className="list-option-drop uk-width-large">
                                                        {(notesSubmit === false) ?
                                                            <Fragment>
                                                                <span className="uk-float-right uk-text-bold uk-text-small">Clarifications</span>
                                                                <textarea name="notes" className="uk-textarea custom uk-text-left" defaultValue={tr.clarifications} onChange={(e) => this.handleChangeNotes(e, "clarifications")}></textarea>
                                                                <div className="uk-margin-top">
                                                                    <button onClick={() => this.handleNotes(tr.id, "clarifications")} type="button" className="uk-button uk-button-small uk-button-primary uk-border-rounded uk-width-1-1">Submit</button>
                                                                </div>
                                                            </Fragment>
                                                            : <span>Submitting...</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        {tr.decoded_data.map((td, index) => (
                                            ((tr.answer_status_id !== 3 && tr.answer_status_id !== 4) ?

                                                <td key={`td-${index}`} id={`row-${tr.id}-${td.codification.code}`}
                                                    onClick={(e) => this.pin(e, `row-${tr.id}-${td.codification.code}`, tr.id, td.codification.code)}
                                                    className={`${(td.is_invalid !== undefined && td.is_invalid === true) ? "bg-color-z edit" : ""} uk-text-nowrap border-right border-bottom`}>

                                                    {(td.codification.options !== undefined) ? (
                                                            <Fragment key={`td-child-${index}`}>
                                                                <div className="uk-inline uk-text-nowrap uk-width-1-1">
                                                                    <button onClick={() => this.handleClickOption(td.answers.map(data => { return data.code }), td.answers.map(data => { return data.value }) )} className="uk-button uk-button-default uk-button-small uk-width-1-1 uk-text-nowrap uk-text-left button-options" type="button">
                                                                        {(td.answers.length > 1) ?
                                                                            td.answers.map((data, index) => (
                                                                                <Fragment key={`td-span-option-${index}`}>
                                                                                    <span>{data.value}</span> {(index < td.answers.length -1) && (<span>, &nbsp;</span>)}
                                                                                </Fragment>
                                                                            ))
                                                                        :
                                                                            (td.answers.length > 0) ?
                                                                                td.answers.map((data, index) => (
                                                                                    data.value
                                                                                ))
                                                                            : "(Empty)"
                                                                        }
                                                                    </button>
                                                                    <div uk-dropdown="mode: click" className="list-option-drop">
                                                                        <ul className="uk-list uk-margin-remove uk-height-max-large" uk-overflow-auto="true">
                                                                            {Object.values(td.codification.options).map((data, index) => (
                                                                                <li key={`lirender-${[index]}`}>
                                                                                    <label><input type="checkbox" className="uk-checkbox uk-border-circle uk-margin-small-right"
                                                                                        onChange={this.handleChangeOption}
                                                                                        id={data.code}
                                                                                        value={data.value}
                                                                                        defaultChecked={(td.answers.find(answer => answer.code === data.code)) ? true : false }
                                                                                    /> {data.value}</label>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                        <div className="uk-margin-top">
                                                                            <button
                                                                                onClick={() => this.unpinOptions(`row-${tr.id}-${td.codification.code}`, tr.id, td.codification.code)}
                                                                                type="button" className="uk-button uk-button-small uk-button-primary uk-border-rounded uk-width-1-1">Submit</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment key={`td-child-${index}`}>
                                                                {td.answers.map((data, index) => (
                                                                <span
                                                                    name="revision"
                                                                    onKeyPress={(e) => this.unpin(e, `row-${tr.id}-${td.codification.code}`, tr.id, td.codification.code)}
                                                                    onFocus={(e) => this.handleClick(e)} key={`td-child-${index}`}
                                                                    className="uk-input uk-form-blank uk-padding-remove bg-color-transparent input" role="textbox" contentEditable={(td.is_invalid !== undefined && td.is_invalid === true) ? true : false} suppressContentEditableWarning={true}>{data.value}</span>
                                                                ))}
                                                            </Fragment>
                                                        )
                                                    }
                                                </td>
                                            :
                                                <td key={`td-${index}`} className="uk-text-nowrap border-right border-bottom">
                                                    {(td.answers.length > 1) ? (
                                                            td.answers.map((data, index) => (
                                                                <Fragment key={`td-child-${index}`}>{data.value}{(td.answers.length === index + 1) ? "" : ", "}</Fragment>
                                                            ))
                                                        ) : (
                                                            td.answers.map((data, index) => (
                                                                <Fragment key={`td-child-${index}`}>{data.value}</Fragment>
                                                            ))
                                                        )
                                                    }
                                                </td>

                                            )
                                        ))}
                                    </tr>
                                </Fragment>

                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default table
