import React, { Component } from 'react'

class error extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="detail-header">
                <div className="empty-image">
                    <p className="uk-text-lead">{this.props.message}</p>
                </div>
            </div>
        )
    }
}

export default error
