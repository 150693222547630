import React, { Component } from 'react';
import * as Icon from 'react-feather';
import { Route } from "react-router-dom";
import detail from './detail';
import Form from './form';
import List from './list';
import Loading from '../partials/loading';
import Empty from '../partials/empty';
import None from '../partials/none';
import Error from '../partials/error';
import axios from 'axios';
import $ from 'jquery';

class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isSort: true,
            sortBy: "newest",
            bulkId: []

        }
    }

    handleBulk = () => {
        if ($('.bulk-items').is(":visible")) {
            const id = this.state.bulkId;
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            axios.delete(api + "/clients/bulk", { data: { id: id }, headers: headers })
                .then(response => {
                    id.map(value => ($('#list-' + value).remove()));
                    this.setState({ bulkId: [] })
                    $('.bulk-items').css('display', 'none');
                }).catch(error => {
                    alert('This client cannot be deleted because it has associated survey data');
                });
        } else {
            this.setState({ bulkId: [] })
            console.log('bulk start!');
            $('.bulk-items').css('display', 'inline');
        }
    }

    handleBulkChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var bulkId = this.state.bulkId;
        var index = bulkId.indexOf(value);
        if (index > -1) {
            bulkId.splice(index, 1);
        }

        if (target.checked) { bulkId.push(value); }
        this.setState({ bulkId: bulkId });
        console.log(this.state.bulkId);
    }

    handleSort = (type) => {
        this.setState({ isSort: false });
        let sort = "";
        if (type === "newest") sort = "?sort_by=created_at|desc";
        else if (type === "oldest") sort = "?sort_by=created_at|asc";
        else if(type === "az") sort = "?sort_by=name|asc";
        else if(type === "za") sort = "?sort_by=name|desc";

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/clients" + sort, { headers })
        .then(response => {
            this.setState({ items: response.data.data, sortBy: type, isSort: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    componentDidMount() {
        let clientList = JSON.parse(localStorage.getItem('cloveClientList'));
        this.setState({ items: clientList, isLoaded: true });
    }

    render() {
        const { error, isLoaded, items } = this.state;

        if (error) {
            return <Error message={error.message} />
        } else if (!isLoaded) {
            return <Loading />
        } else {
            if (items.length > 0) {
                return (
                    <div>
                        <div className="uk-grid-small uk-grid-match-height uk-viewport-height" uk-grid="true">
                            <div className="list-frame uk-width-1-3@m uk-viewport-height">
                                <div className="list-header">
                                    <div className="uk-grid-small uk-flex-middle" uk-sticky="offset: 0" uk-grid="true">
                                        <div className="uk-width-expand">
                                            <strong className="text-color-primary">Clients</strong>
                                        </div>
                                        <div className="uk-width-auto">
                                            <div className="uk-button-group rounded">
                                                <button uk-tooltip="title: Sort; pos: left" type="button" className="uk-button uk-button-small uk-border-rounded"><Icon.Filter size={16} /></button>
                                                <div uk-dropdown="mode: click; pos: left-top">
                                                    {(this.state.isSort) ?
                                                        <ul className="uk-nav uk-dropdown-nav">
                                                            <li><button onClick={() => this.handleSort('newest')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'newest' ? 'text-color-primary' : ''}`}>Newest</button></li>
                                                            <li className="uk-nav-divider"></li>
                                                            <li><button onClick={() => this.handleSort('oldest')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'oldest' ? 'text-color-primary' : ''}`}>Oldest</button></li>
                                                            <li className="uk-nav-divider"></li>
                                                            <li><button onClick={() => this.handleSort('az')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'az' ? 'text-color-primary' : ''}`}>A - Z</button></li>
                                                            <li className="uk-nav-divider"></li>
                                                            <li><button onClick={() => this.handleSort('za')} type="button" className={`uk-button uk-button-small uk-button-default ${this.state.sortBy === 'za' ? 'text-color-primary' : ''}`}>Z - A</button></li>
                                                        </ul>
                                                    : <span className="uk-text-small">Loading...</span>}
                                                </div>
                                                <button onClick={() => this.handleBulk()} uk-tooltip="title: Delete; pos: left" type="button" className="uk-button uk-button-small">
                                                    <Icon.Trash2 size={16} />
                                                </button>
                                                <button type="button" uk-toggle="target: #modal-add" className="uk-button uk-button-small uk-border-rounded uk-button-primary red">
                                                    <Icon.Plus size={16} /> Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="uk-list uk-list-divider uk-margin-remove">
                                    <List items={items} handleBulkChange={this.handleBulkChange} />
                                </ul>
                            </div>
                            <div className="detail-frame uk-width-2-3@m uk-viewport-height">
                                <Route exact path="/client" component={None} />
                                <Route path="/client/detail/:id" component={detail} />
                            </div>
                        </div>
                        <Form />
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className="list-header">
                            <div className="uk-grid-small uk-flex-middle" uk-sticky="offset: 0" uk-grid="true">
                                <div className="uk-width-expand">
                                    <strong className="text-color-primary">Clients</strong>
                                </div>
                                <div className="uk-width-auto">
                                    <div className="uk-button-group">
                                        <button type="button" uk-toggle="target: #modal-add" className="uk-button uk-button-small uk-border-rounded uk-button-primary red">
                                            <Icon.Plus size={16} /> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form />
                        <Empty />
                    </div>
                )
            }
        }
    }
}

export default Index
