import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import axios from 'axios';
import * as Icon from 'react-feather';
import Loading from '../partials/loading';
import Error from '../partials/error';
import moment from 'moment';

class settings extends Component {
    constructor(props) {
        super(props)

        const id = this.props.match.params.id;
        this.state = {
            error: null,
            isRedirect: false,
            isLoaded: false,
            isRoleLoaded: false,
            items: [],
            survey: [],
            client: [],
            roles: [],
            permissions: [],
            survey_id: id,
        }
    }

    handleChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var permissions = this.state.permissions;
        var index = permissions.indexOf(value);
        if (index > -1) {
            permissions.splice(index, 1);
        }

        if (target.checked) { permissions.push(value); }
        this.setState({ permissions: permissions });
    }

    handleSubmit = (event) => {
        this.setState({ isLoaded: false, isRoleLoaded: false });
        let permissions = this.state.permissions;
        permissions = permissions.filter((_, index) => permissions.hasOwnProperty(index))

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const survey_id = this.state.survey_id;

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let post = {
            role_id: permissions
        }

        axios.put(api + "/surveys/" + survey_id + "/survey-access", post, { headers })
        .then(response => {
            this.setState({ items: response.data.data, isLoaded: true, });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
            this.setState({ isLoaded: true, });
        });
        event.preventDefault();
    }

    componentDidMount(){
        this.setState({ isLoaded: false, isRoleLoaded: false });
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const survey_id = this.state.survey_id;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let target = "list-" + survey_id;

        setTimeout(() => {
            var list = document.getElementsByClassName("list");
            Array.prototype.forEach.call(list, function (list, index) {
                list.setAttribute("class", "list");
            });

            var element = document.getElementById(target);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(target).setAttribute("class", "list active");
            }
        }, 100);

        axios.get(api + "/surveys/" + survey_id, { headers })
        .then(response => {
            var roles = response.data.data.roles;
            var x = [];
            roles.map(value => {
                return x.push(value.id);
            });

            this.setState({ survey: response.data.data, roles: response.data.data.roles, permissions: x, client: response.data.data.client,isLoaded: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        axios.get(api + "/roles", { headers })
        .then(response => {
            this.setState({ items: response.data.data, isRoleLoaded: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { error, isLoaded, isRoleLoaded, survey, items, isRedirect, roles } = this.state;
        if (isRedirect) {
            return <Redirect to={`/archived/detail/${this.state.survey_id}`}/>;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isLoaded) {
                return <Loading />
            } else {

                return (
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="detail-header uk-padding-remove-bottom">
                                <div className="uk-grid-small uk-flex-middle border-bottom uk-margin-bottom" uk-grid="true">
                                    <div className="uk-width-auto uk-padding-remove-left">
                                        <Link to={`/archived/detail/${this.state.survey_id}`} className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.ArrowLeft size={20} /></Link>
                                    </div>
                                    <div className="uk-width-expand uk-padding-remove-left">
                                        <strong className="text-color-primary">
                                            {survey.name}
                                        </strong>
                                    </div>
                                    <div className="uk-width-auto">
                                        <div className="uk-button-group">
                                            <button type="submit" className="uk-button uk-button-small uk-button-primary red uk-border-rounded"><Icon.Check size={16} /> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><strong>Role access</strong></p>
                            <hr/>

                            {/* body */}
                            <div className="uk-width-1-1">
                                <ul className="uk-list uk-list-divider uk-margin-remove">
                                    {(isRoleLoaded) ?
                                    items.map((value) => (
                                        <li id={`survey-rolelist-${value.id}`} key={`survey-rolelist-${value.id}`}>
                                            <div className="uk-grid-small uk-flex-top uk-padding-small uk-padding-remove-vertical " uk-grid="true">
                                                <div className="uk-width-auto">
                                                    <input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value={value.id} onChange={this.handleChange} defaultChecked={(roles.findIndex(item => item.id === value.id) >= 0 ? true : false)} />
                                                </div>
                                                <div className="uk-width-expand">
                                                    <span className="uk-float-right uk-text-small uk-text-right">{moment(value.created_at).format("D MMM")}, {moment(value.created_at).format("YYYY")}</span>
                                                    <p className="uk-margin-remove">{value.name}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )) : <Loading /> }
                                </ul>
                            </div>
                        </form>
                    </div>
                )
            }
        }
    }
}

export default settings
