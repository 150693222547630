import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';


const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

class NavigationTop extends Component {
    constructor(props) {
        super(props)

        const profile = JSON.parse(localStorage.getItem('cloveProfile'));
        this.state = {
            name: profile.name,
            id: profile.id
        }
    }

    activePage = (routeID) => {
        setTimeout(() => {
            var link = document.getElementsByClassName("link");
            Array.prototype.forEach.call(link, function (link, index) {
                link.setAttribute("class", "link");
            });

            let pageState = sessionStorage.getItem('localState');
            if(pageState === ""){
                pageState = {
                    lastPage: "",
                    activePage: routeID
                };
            } else {
                pageState = JSON.parse(pageState);

                pageState = {
                    lastPage: (pageState!==null && pageState.activePage !== undefined ? pageState.activePage : ""),
                    activePage: routeID
                };
            }

            if (pageState.lastPage !== "") { document.getElementById(pageState.lastPage).setAttribute("class", "link"); }
            document.getElementById(routeID).setAttribute("class", "link active");

            sessionStorage.setItem('localState', JSON.stringify(pageState));
        }, 100);
    }

    render() {
        const {logo} = this.props
        return (
            <div className="navigation-top uk-background-primary red">
                <div className="uk-navbar-container uk-navbar-transparent" uk-navbar="true">
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            {/* <li>
                                <button type="button" className="uk-button uk-button-small uk-button-primary"><span className="icon" uk-icon="icon:grid; ratio:1"></span></button>
                            </li> */}
                            <li>
                                <div className="logo"><img src={logo} alt="Logo clove" /></div></li>
                            <li>
                                <span className="uk-light uk-text-small brand"><b>Clove</b><br/>Survey manager</span>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav uk-flex-middle">
                            <li id="link-home">
                                <Link to="/notifications" onClick={() => this.activePage('link-home')} className="uk-button uk-button-small uk-button-default uk-padding-remove">
                                    <Icon.Grid size={16} />
                                </Link>
                            </li>
                            {/* <li id="link-notifications">
                                <Link to="/notifications" onClick={() => this.activePage('link-notifications')} className="uk-button uk-button-small uk-button-default uk-padding-remove">
                                    <Icon.Bell size={16} />
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/settings/profile" onClick={() => this.activePage('link-settings')} className="uk-button uk-button-small uk-button-default uk-padding-remove">
                                    <span className="uk-light uk-text-small uk-margin-small-right uk-text-capitalize">{this.state.name}</span>
                                    <span className={`initial-name bg-color-${(this.state.id.length > 1 ? this.state.id.substring(1, 3) : this.state.id)}`}>{toInitials(this.state.name)}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/settings" onClick={() => this.activePage('link-settings')} className="uk-button uk-button-small uk-button-default uk-padding-remove">
                                    <Icon.Settings size={16} />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavigationTop
