import React, { Component, Fragment } from 'react'
import axios from 'axios';

class form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            isLoaded: false,
            client: [],
            code: "",
            name: "",
            client_id: "",
            description: "",
            submit: false
        };
    }

    componentDidMount() {
        let clientList = JSON.parse(localStorage.getItem('cloveClientList'));
        this.setState({ client: clientList });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ submit: true });
        const { code, name, client_id, description } = this.state;
        const post = { code: code, name: name, client_id: client_id, description: description }
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/surveys", post, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        event.preventDefault();
    }

    render() {
        const { client, submit } = this.state;

        return (
            <div id="modal-survey" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-xlarge uk-margin-auto-vertical">
                    {(submit === false) ?
                        <Fragment>
                            <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                            <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                                <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                                    <div className="uk-padding uk-padding-remove-bottom body-frame">
                                        <p>
                                            <strong>New survey</strong><br />
                                            Please fill in all required fields marked with (*)
                                        </p>
                                        <div className="uk-grid-smal≈l uk-child-width-1-2 uk-margin-bottom" uk-grid="true">
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Survey Code (*)</label>
                                                    <input className="uk-input custom" placeholder="..." type="text" name="code" value={this.state.code} onChange={this.handleChange}  required/>
                                                </div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Survey name (*)</label>
                                                    <input className="uk-input custom" placeholder="..." type="text" name="name" value={this.state.name} onChange={this.handleChange}  required/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Client (*)</label>
                                                    <select className="uk-select custom" name="client_id" value={this.state.client_id} onChange={this.handleChange} required>
                                                        <option value="">-</option>
                                                        {client.map(option => (
                                                            <option key={`option-${option.id}`} value={option.id}>{option.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label uk-text-small">Description (*)</label>
                                                    <textarea className="uk-textarea custom" rows="3" placeholder="..." name="description" value={this.state.description} onChange={this.handleChange} required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-modal-footer">
                                    <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                                </div>
                            </form>
                        </Fragment>
                    :
                        <div className="uk-modal-body no-header no-footer uk-padding-remove">
                            <div className="uk-padding-remove body-frame">
                                <p className="uk-text-center uk-margin-top">Submitting...</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )

    }
}

export default form
