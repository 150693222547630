import React, { Component } from 'react';
import { Redirect } from 'react-router'
import Loading from '../partials/loading';
import Error from '../partials/error';
import Edit from './edit';
import axios from 'axios';
import $ from 'jquery';
import * as Icon from 'react-feather';
import moment from 'moment';

const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

class detail extends Component {
    constructor(props) {
        super(props)

        const id = this.props.match.params.id;
        this.state = {
            error: null,
            isRedirect: false,
            isLoaded: false,
            items: [],
            id: id,
        }
    }

    handleDelete = () => {
        const id = this.state.id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/interviewers/ " + id, { headers })
            .then(response => {
                this.setState({isRedirect: true});
                $("#list-"+id).remove();
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    componentDidMount(){
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const id = this.props.match.params.id;

        // list identifier
        let target = "list-" + id;

        setTimeout(() => {
            var list = document.getElementsByClassName("list");
            Array.prototype.forEach.call(list, function (list, index) {
                list.setAttribute("class", "list");
            });

            var element = document.getElementById(target);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(target).setAttribute("class", "list active");
            }
        }, 100);

        // this.props.location.state.id
        axios.get(api + "/interviewers/" + id, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => {
            this.setState({
                isLoaded: true,
                items: response.data.data
            });
        }).catch(error => {
            this.setState({
                error: error
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const id = this.props.match.params.id;
        // this.props.location.state.id
        if (prevState.id !== id) {
            this.setState({
                isLoaded: false,
                id: id
            });

            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            axios.get(api + "/interviewers/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                this.setState({
                    isLoaded: true,
                    items: response.data.data,
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                });
            });
        }
    }

    render() {
        const { error, isLoaded, items, isRedirect } = this.state;

        if (isRedirect) {
            return <Redirect to='/interviewer' />;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isLoaded) {
                return <Loading />
            } else {
                return (
                    <div>
                        <div className="detail-header border-bottom uk-margin-bottom">
                            <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                <div className="uk-width-expand">
                                    <h5>Information</h5>
                                </div>
                                <div className="uk-width-auto">
                                    <div className="uk-button-group">
                                        <a href="/#" uk-toggle="target: #modal-edit" uk-tooltip="title: Edit; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Edit3 size={16} /></a>
                                        <button type="button" onClick={() => this.handleDelete()} uk-tooltip="title: Delete; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Trash2 size={16} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-clearfix"></div>
                        <div className="uk-margin-top uk-flex-top" uk-grid="true">
                            <div className="uk-width-auto uk-text-center">
                                <div className="uk-margin-bottom uk-text-center">
                                    <span className={`initial-name big bg-color-${(items.id.length > 1 ? items.id.substring(1, 3) : items.id)}`}>{toInitials(items.name)}</span>
                                </div>
                                <h4 className="uk-margin-remove uk-text-center">
                                    {items.name}
                                </h4>
                                <p className="uk-text-small uk-margin-remove-top">Created : {moment(items.created_at).format("D MMM")} {moment(items.created_at).format("YYYY")}</p>
                                <div className="uk-margin-bottom uk-margin-large-top">
                                    <a href={`mailto:${items.email}`} rel="noopener noreferrer" target="_blank" className="uk-button uk-button-primary uk-button-small uk-border-pill">Send email</a>
                                </div>
                                <div className="uk-button-group">
                                    <a href={items.instagram} rel="noopener noreferrer" target="_blank" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Instagram size={16} /></a>
                                    <a href={items.facebook} rel="noopener noreferrer" target="_blank" className="uk-button uk-button-default uk-button-small uk-text-center border-left"><Icon.Facebook size={16} /></a>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="uk-width-4-5@m">
                                    <p>
                                        <strong>Address</strong><br />
                                        {items.address}
                                    </p>
                                    <p>
                                        <strong>Postal code</strong><br />
                                        {items.postal_code}
                                    </p>
                                    <hr/>
                                    <div className="uk-grid-small uk-child-width-1-2" uk-grid="true">
                                        <div>
                                            <p>
                                                <strong>Phone</strong><br />
                                                {items.phone}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <strong>Telephone</strong><br />
                                                {items.telephone}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <strong>Email</strong><br />
                                                {items.email}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <strong>Instagram</strong><br />
                                                {items.instagram}
                                            </p>
                                        </div>
                                        <div>
                                            <p>
                                                <strong>Facebook</strong><br />
                                                {items.facebook}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Edit survey_id={this.state.id} items={items} />
                    </div>
                )
            }
        }
    }
}

export default detail
