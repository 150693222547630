import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import axios from 'axios';
import * as Icon from 'react-feather';
import Loading from '../partials/loading';
import Error from '../partials/error';

class respondent extends Component {
    constructor(props) {
        super(props)

        const id = this.props.match.params.id;
        const survey_id = this.props.match.params.survey_id;
        this.state = {
            error: null,
            isRedirect: false,
            isLoaded: false,
            items: [],
            id: id,
            survey_id: survey_id
        }
    }

    handleDelete = () => {
        const id = this.state.id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.delete(api + "/answers/" + id, { headers })
            .then(response => {
                this.setState({ isRedirect: true });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    componentDidMount(){
        const id = this.state.id;
        const survey_id = this.state.survey_id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/answers/" + id, { headers })
        .then(response => {
            this.setState({ items: response.data.data, isLoaded: true });
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        // list identifier
        let target = "list-" + survey_id;
        setTimeout(() => {
            var list = document.getElementsByClassName("list");
            Array.prototype.forEach.call(list, function (list, index) {
                list.setAttribute("class", "list");
            });

            var element = document.getElementById(target);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(target).setAttribute("class", "list active");
            }
        }, 100);
    }

    handleSelect = (event, answerID) => {
        this.setState({ isLoaded: false });

        let value = event.target.value;
        let post = { "id": [answerID], "answer_status_id": value };
        let items = this.state.items;
        console.log(items);

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.patch(api + "/answers/bulk", post, { headers }).then(response => {
            items.answer_status_id = value;
            this.setState({ items: items });
            setTimeout(() => {
                this.setState({ isLoaded : true });
            }, 500);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    render() {
        const { error, isLoaded, items, isRedirect, survey_id } = this.state;
        const data = items.decoded_data;
        if (isRedirect) {
            return <Redirect to={`/active/detail/${survey_id}`}/>;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isLoaded) {
                return <Loading />
            } else {

                return (
                    <div>
                        <div className="detail-header uk-padding-remove-bottom">
                            <div className="uk-grid-small uk-flex-middle border-bottom uk-margin-bottom" uk-grid="true">
                                <div className="uk-width-auto uk-padding-remove-left">
                                    <Link to={`/active/detail/${survey_id}`} className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.ArrowLeft size={20} /></Link>
                                </div>
                                <div className="uk-width-expand uk-padding-remove-left">
                                    <strong className="text-color-primary">
                                        {data.map((item, index) => (
                                            (item.codification.identifier === "name") ?
                                                item.answers.map((subitem, index) => (
                                                    <span key={`name-${index}`}>{subitem.value}</span>
                                                ))
                                            : ""
                                        ))}
                                    </strong>
                                </div>
                                <div className="uk-width-auto uk-padding-small uk-padding-remove-vertical">
                                    <div className="uk-button-group">
                                        <div onChange={(e) => this.handleSelect(e, items.id)} id={`statusSelect-frame-${items.id}`} uk-form-custom="target: > * > span:first-child" className="uk-margin-small-right">
                                            <select id-answer={items.id} defaultValue={items.answer_status_id} className="statusSelect">
                                                <option value="2">Audio check</option>
                                                <option value="6">QC check</option>
                                                <option value="5">Field check</option>
                                                <option value="4">Final</option>
                                                <option value="7">Drop out</option>
                                            </select>
                                            <span className="uk-link">
                                                <span className={`statusSelect-label uk-text-center uk-label ${(items.answer_status_id === 2) ? `uk-label-warning` : ``} ${(items.answer_status_id === 3) ? `uk-label-primary` : ``} ${(items.answer_status_id === 4) ? `uk-label-muted` : ``} ${(items.answer_status_id === 5) ? `uk-label-success` : ``} uk-border-pill uk-text-capitalize`}></span>
                                            </span>
                                        </div>
                                        <button type="button" onClick={() => this.handleDelete()} uk-tooltip="title: Delete; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Trash2 size={16} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* body */}
                        <div className="uk-grid-match uk-margin-large-bottom" uk-grid="true">
                            <div className="uk-width-4-5@m uk-padding-right-small">
                                <div>
                                    <div className="uk-grid-small uk-grid-match" uk-grid="true">
                                        <div className="uk-width-small uk-padding-small">
                                            <p className="uk-margin-remove uk-text-truncate">NOTES</p>
                                        </div>
                                        <div className="uk-width-expand padding-right">
                                            <p>
                                                <strong className="uk-text-primary">{items.notes}</strong><br />
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="uk-margin-remove-bottom uk-padding-remove-horizontal" />
                                </div>
                                {data.map((item, index) => (
                                    <div key={`list-code-${index}`}>
                                        <div className="uk-grid-small uk-grid-match" uk-grid="true">
                                            <div className="uk-width-small uk-padding-small">
                                                <p className="uk-margin-remove uk-text-truncate">{item.codification.code}</p>
                                            </div>
                                            <div className="uk-width-expand padding-right">
                                                <p className="uk-margin-remove-bottom uk-margin-small-top">
                                                    <strong>{item.codification.value}</strong><br/>
                                                    {item.codification.subvalue}
                                                </p>
                                                <hr className="uk-margin-small-bottom uk-margin-small-top"/>
                                                {item.answers.map((subitem, index) => (
                                                    <p key={`list-answer-${index}`} className="uk-margin-small-bottom uk-margin-remove-top">{subitem.value}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="uk-margin-remove uk-padding-remove-horizontal" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default respondent
