import React, { Component } from 'react'
import { Link } from "react-router-dom";
import moment from 'moment';
import * as Icon from 'react-feather';

const activeLink = () => {
    setTimeout(() => {
        var link = document.getElementsByClassName("link");
        Array.prototype.forEach.call(link, function (link, index) {
            link.setAttribute("class", "link");
        });

        document.getElementById('link-active').setAttribute("class", "link active");

    }, 100);
}

class surveys extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        const items = this.props.items;
        return (
            <ul className="uk-list uk-list-divider">
                { items.map((value) => (
                        <li id={`csurveys-${value.id}`} key={`list-${value.id}`}>
                            <Link to={{
                                pathname: `/active/detail/${value.id}`,

                            }} onClick={() => activeLink()}>
                                <div className="uk-grid-small uk-flex-middle uk-padding-small uk-padding-remove-vertical " uk-grid="true">
                                    <div className="uk-width-1-6 uk-padding-remove">
                                        <p className="uk-text-truncate">{value.name}</p>
                                    </div>
                                    <div className="uk-width-1-6">
                                        <p className="uk-text-truncate">{value.code}</p>
                                    </div>
                                    <div className="uk-width-expand uk-padding-remove">
                                        <p className="uk-text-truncate">{value.description}</p>
                                    </div>
                                    <div className="uk-width-1-6 uk-text-center">
                                        <span className="uk-text-small">{moment(value.created_at).format("D MMM")} {moment(value.created_at).format("YYYY")}</span>
                                    </div>
                                    <div className="uk-width-auto">
                                        <div className="uk-button-group">
                                            <button uk-tooltip="title: Archive; pos: left;" type="button" className="uk-button uk-button-default uk-button-small"><Icon.Archive size={16} /></button>
                                            <button uk-tooltip="title: Delete; pos: left;" type="button" className="uk-button uk-button-default uk-button-small border-left"><Icon.Trash2 size={16} /></button>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))
                }
            </ul>
        )
    }
}

export default surveys
