import React, { Component } from 'react';
// import * as Icon from 'react-feather';
import { Link } from "react-router-dom";

class index extends Component {
    signout = () => {
        localStorage.clear()
        setTimeout(() => {
            document.location = "/";
        }, 100);
    }

    render() {
        return (
            <div>
                <div className="detail-header">
                    <strong className="text-color-primary uk-margin-small-left">Settings</strong>
                </div>
                <div className="uk-width-1-2@m uk-padding-small">
                    <ul className="uk-list uk-list-divider">
                        {/* <li>
                            <button className="uk-button uk-button-default uk-float-right" type="button" uk-toggle="target: .switch-auto-translate">
                                <span className="switch-auto-translate"><Icon.ToggleLeft uk-tooltip="title: Off; pos: top" size={32} /></span>
                                <span className="switch-auto-translate" hidden><Icon.ToggleRight uk-tooltip="title: On; pos: top" size={32} /></span>
                            </button>
                            <strong>Auto translate</strong><br/>
                            The system will automatically translate after importing survey data
                        </li> */}
                        <li>
                            <Link to="/settings/roles">
                                <strong>User Roles</strong><br/>
                                Permission for users to perform a group of tasks and restrictions for accessing functions
                            </Link>
                        </li>
                        <li>
                            <Link to="/settings/users">
                                <strong>Users</strong><br/>
                                List of users who have been granted permission to access this system
                            </Link>
                        </li>
                        <li>
                            <Link to="/settings/profile">
                                <strong>Profile</strong><br/>
                                Short description of you account that gives important and useful details
                            </Link>
                        </li>
                        <li>
                            <button onClick={() => this.signout()} type="button" className="uk-button uk-button-link"><strong>Sign Out</strong></button>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default index
