import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import FormCodification from './codification';
import Edit from './edit';
import CloneCodification from './clone';
import FormAnswer from './importAnswer';
import ImportCodification from './importCodification';
import BarChart from './bar';
import Loading from '../partials/loading';
import Error from '../partials/error';
import Empty from '../partials/empty';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import * as Icon from 'react-feather';
import { Redirect } from 'react-router'

class detail extends Component {
    constructor(props) {
        super(props)

        const id = this.props.match.params.id;

        let page = (localStorage.getItem('cloveDataPageJobs') !== "" && localStorage.getItem('cloveDataPageJobs') !== null) ? localStorage.getItem('cloveDataPageJobs') : 1;
        let per_page = (localStorage.getItem('cloveDataPerPageJobs') !== "" && localStorage.getItem('cloveDataPerPageJobs') !== null)  ? localStorage.getItem('cloveDataPerPageJobs') : 25;
        let last_page = (localStorage.getItem('cloveDataLastPageJobs') !== "" && localStorage.getItem('cloveDataLastPageJobs') !== null) ? localStorage.getItem('cloveDataLastPageJobs') : 1;
        let answer_status_id = (localStorage.getItem('cloveAnswerStatusId') !== "" && localStorage.getItem('cloveAnswerStatusId') !== null) ? localStorage.getItem('cloveAnswerStatusId') : 1;
        let tabs = (localStorage.getItem('cloveTabsJobs') !== "" && localStorage.getItem('cloveTabsJobs') !== null)? localStorage.getItem('cloveTabsJobs') : 1;

        this.state = {
            error: null,
            isRedirect: false,
            isRedirectDetail: false,
            isInformationLoaded: false,
            isCodificationLoaded: false,
            isAnswerLoaded: false,
            isOptionLoaded: false,
            displayInformation: false,
            information: [],
            items: [],
            client: [],
            codification: [],
            option: [],
            respondent: [],
            codification_id: '',
            code: '',
            value: '',
            sub_value: '',
            id: id,
            bulkId: [],
            page: page,
            per_page: per_page,
            last_page: last_page,
            pagination: [],
            total_data: 0,
            survey_id: id,
            answer_status_id: answer_status_id,
            tabs: tabs,
            isSummaryLoaded: false,
            summary: {"audio_check":0,"qc_check":0,"field_check":0,"final":0,"drop_out":0}
        }
    }

    handleTabs = (tabs) => {
        this.setState({ tabs: tabs});
        localStorage.setItem('cloveTabsJobs', tabs);
    }

    handleDeleteCodification = (id) => {
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/codifications/ " + id, { headers })
            .then(response => {
                this.setState({ isRedirectDetail: true });
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    handleExport = () => {
        const id = this.state.id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/answers/export", { headers })
            .then(response => {
                this.setState({ isRedirect: true });
                $("#list-" + id).remove();
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    handleArchive = () => {
        const id = this.state.id;
        const items = this.state.items;
        const post = { is_archived: 1, name: items.name, code: items.code, client_id: items.client_id};
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.put(api + "/surveys/" + id, post, { headers })
        .then(response => {
            if (response.data) {
                this.setState({ isRedirect: true });
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
            } else {
                alert('archive error!');
            }
        });
    }

    translate = (e) => {
        this.setState({isAnswerLoaded: false });
        const post = { id: this.state.respondent.map(respondent => respondent.id) }
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/answers/translate", post, { headers })
        .then(response => {
              axios.get(api + "/answers?per_page=50&survey_id=" + this.props.match.params.id, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                this.setState({
                    isAnswerLoaded: true,
                    respondent: response.data.data,
                    answer_status_id: '2'
                });
                // console.log('respondent', response.data.data);
            }).catch(error => {
                this.setState({
                    error: error
                });
            });
        }).catch(error => {
            this.setState({isAnswerLoaded: true});
            let str = "";
            if (error.response.data.data !== undefined) {
                str = JSON.stringify(error.response.data.data).replace("{", "").replace("}", "").replace(",", "\r\n\r\n");
            }
            let message = error.response.data.message + "\r\n\r\n" + str;
            alert(message);
        });
    }

    handleDelete = () => {
        this.setState({ isInformationLoaded: false })
        const id = this.state.id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/surveys/ " + id, { headers })
            .then(response => {
                this.setState({ isRedirect: true });
                $("#list-" + id).remove();
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    viewOptions = (routeID, codification_id) => {

        this.setState({ isOptionLoaded: false, codification_id: codification_id, code: '', value: '', sub_value: '' });

        $('#' + routeID).addClass('uk-open');

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/options?sort_by=code|asc&codification_id=" + codification_id, { headers })
        .then(response => {
            console.log('view', response);
            this.setState({option: response.data.data, isOptionLoaded: true});
        });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDeleteOption = (id) => {
        this.setState({ isOptionLoaded: false });
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/options/ " + id, { headers })
            .then(response => {
                this.setState({ option: this.state.option.filter(item => item.id !== id), isOptionLoaded: true });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
    }

    handleSubmitOption = (event) => {
        event.preventDefault();

        this.setState({ isOptionLoaded: false });
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        const post = {
            codification_id: this.state.codification_id,
            code: this.state.code,
            value: this.state.value,
            sub_value: this.state.sub_value
        };
        axios.post(api + "/options", post, { headers })
            .then(response => {
                let data = response.data.data;
                let options = this.state.option;
                options.push({
                    id: data.id,
                    codification_id: post.codification_id,
                    code: data.code,
                    value: data.value,
                    sub_value: data.sub_value,
                    created_at: data.created_at,
                    updated_at: data.updated_at
                });
                this.setState({ option: options, isOptionLoaded: true, code: '', value: '', sub_value: '' });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });

        event.preventDefault();
    }

    handleDeleteRAW = () => {
        this.setState({ isAnswerLoaded: false });
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/answers?&survey_id=" + this.state.survey_id + "&answer_status_id=1&all=1", { headers }).then(response => {
            console.log(response.data.data);
            let items = response.data.data;
            let id = [];
            items.map((x, index) => {
                id[index] = x.id;
                return true;
            });

            axios.delete(api + "/answers/bulk", { data: { id: id }, headers: headers }).then(response => {
                id.map(value => ($('#answersList-' + value).remove()));
                this.setState({ respondent: [], isAnswerLoaded: true });
            }).catch(error => {
                let message = error.response.data.message;
                alert(message);
                this.setState({ isAnswerLoaded: true });
            });
        }).catch(error => {

            console.log(error);
            alert("Something is wrong!");
        });


    }

    handleBulkDelete = (component) => {
        if ($('.bulk-items.' + component).is(":visible")) {
            const id = this.state.bulkId;
            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            axios.delete(api + "/" + component + "/bulk", { data: { id: id }, headers: headers })
                .then(response => {
                    id.map(value => ($('#' + component + 'List-' + value).remove()));
                    this.setState({ bulkId: [] })
                    $('.bulk-items.' + component).css('display', 'none');
                }).catch(error => {
                    let message = error.response.data.message;
            alert(message);
                });
        } else {
            this.setState({ bulkId: [] })
            console.log('bulk start!');
            $('.bulk-items.' + component).css('display', 'inline');
        }
    }

    handleBulkChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var bulkId = this.state.bulkId;
        var index = bulkId.indexOf(value);
        if (index > -1) {
            bulkId.splice(index, 1);
        }

        if (target.checked) { bulkId.push(value); }
        this.setState({ bulkId: bulkId });
        console.log(this.state.bulkId);
    }

    handleDownload = () => {
        const api = localStorage.getItem('cloveApi');
        let items = this.state.respondent;
        let itemsLength = items.length;
        let bulkSurvey = ["", ""];
        let bulkSurveyId = 0;
        let bulkIndex = -1;
        items.map((x, index) => {
            if (bulkSurveyId !== x.survey_id) {
                bulkSurveyId = x.survey_id;
                bulkIndex++;
            }

            bulkSurvey[bulkIndex] += "id[]=" + x.id + ((itemsLength !== (index + 1)) ? "&" : "");
            return true;
        });

        let x = [];
        for (let loop = 0; loop < bulkSurvey.length; loop++) {
            x[loop] = window.open(api + "/answers/export?" + bulkSurvey[loop], '_blank');
        }
    }

    componentDidMount(){
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const id = this.props.match.params.id;

        // list identifier
        let target = "list-" + id;
        setTimeout(() => {
            var list = document.getElementsByClassName("list");
            Array.prototype.forEach.call(list, function (list, index) {
                list.setAttribute("class", "list");
            });

            var element = document.getElementById(target);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(target).setAttribute("class", "list active");
            }
        }, 100);

        // this.props.location.state.id
        axios.get(api + "/surveys/" + id, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => {
            this.setState({
                isInformationLoaded: true,
                items: response.data.data,
                client: response.data.data.client
            });
        }).catch(error => {
            this.setState({
                error: error
            });
        });

        axios.get(api + "/codifications?all=1&survey_id=" + id, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => {
            this.setState({
                isCodificationLoaded: true,
                codification: response.data.data,
            });
        }).catch(error => {
            this.setState({
                error: error
            });
        });

        this.handleRefreshPage();
        this.handleRefreshSummary();
    }

    componentDidUpdate(prevProps, prevState){
        const id = this.props.match.params.id;

        if (prevState.id !== id) {
            this.setState({
                isInformationLoaded: false,
                isCodificationLoaded: false,
                isAnswerLoaded: false,
                id: id,
                survey_id: id
            });

            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');
            axios.get(api + "/surveys/" + id, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                this.setState({
                    isInformationLoaded: true,
                    items: response.data.data,
                    client: response.data.data.client
                });
            }).catch(error => {
                console.log(error);
            });

            axios.get(api + "/codifications?all=1&survey_id=" + id, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(response => {
                this.setState({
                    isCodificationLoaded: true,
                    codification: response.data.data,
                });
                console.log('codification', this.state.codification);
            }).catch(error => {
                this.setState({
                    error: error
                });
            });

            setTimeout(() => {
                this.handleRefreshPage();
                this.handleRefreshSummary();
            }, 500);
        }
    }

    handleStatus = (event) => {
        event.preventDefault();

        this.setState({ answer_status_id: event.target.value, page: 1 });

        setTimeout(() => { this.handleRefreshPage(); }, 200);
    }

    handlePagging = (event, page, type = "page") => {
        event.preventDefault();

        if (type === "per_page") { this.setState({ per_page: event.target.value, page: 1 }); }
        else { this.setState({ page: page }); }

        setTimeout(() => { this.handleRefreshPage(); }, 200);

        console.log(event, page, type, event.target.value);
    }

    handleRefreshPage = () => {
        this.setState({ isAnswerLoaded: false });

        let answer_status_id = this.state.answer_status_id;
        let filter_page = (this.state.per_page !== "") ? "page=" + this.state.page : "";
        let filter_per_page = (this.state.per_page !== "") ? "&per_page=" + this.state.per_page : "&per_page=9999";
        let filter_survey = "&survey_id=" + this.state.survey_id;
        let filter_status = (parseInt(answer_status_id) !== 0) ? "&answer_status_id=" + answer_status_id : "";

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept': 'application/json' };

        axios.get(api + "/answers?" + filter_page + filter_per_page + filter_survey + filter_status + "&sort_by=survey_id|desc", { headers }).then(response => {
            let page = response.data.meta.current_page
            let per_page = response.data.meta.per_page
            let last_page = response.data.meta.last_page
            let pagination = this.pageNumbers(parseInt(last_page), parseInt(page), this.handlePagging);

            this.setState({ respondent: response.data.data, isAnswerLoaded: true, page: page, per_page: per_page, last_page: last_page, pagination: pagination });

            localStorage.setItem('cloveDataPageJobs', page);
            localStorage.setItem('cloveDataPerPageJobs', per_page);
            localStorage.setItem('cloveDataLastPageJobs', last_page);
            localStorage.setItem('cloveAnswerStatusId', answer_status_id);
        }).catch(error => {
            this.setState({ isAnswerLoaded: true });

            console.log(error);
            // let message = error.response.data.message;
            alert("Something is wrong!");
        });
    }

    pageNumbers(count, current, handle) {
        var shownPages = 5;
        var result = [];

        if ((count - shownPages) !== 0 && current > (count - shownPages)) {

            if (count > 3) {
                result.push(
                    <li key={1}><a href="/#" onClick={(e) => handle(e, 1)}>{1}</a></li>,
                    <li key={`disable-${count}`} className="uk-disabled"><span>...</span></li>,
                    <li className={`uk-border-circle ${((count - 5) === current) && "uk-active"}`} key={count - 5}><a href="/#" onClick={(e) => handle(e, count - 5)}>{count - 5}</a></li>,
                    <li className={`uk-border-circle ${((count - 4) === current) && "uk-active"}`} key={count - 4}><a href="/#" onClick={(e) => handle(e, count - 4)}>{count - 4}</a></li>,
                    <li className={`uk-border-circle ${((count - 3) === current) && "uk-active"}`} key={count - 3}><a href="/#" onClick={(e) => handle(e, count - 3)}>{count - 3}</a></li>,
                    <li className={`uk-border-circle ${((count - 2) === current) && "uk-active"}`} key={count - 2}><a href="/#" onClick={(e) => handle(e, count - 2)}>{count - 2}</a></li>,
                    <li className={`uk-border-circle ${((count - 1) === current) && "uk-active"}`} key={count - 1}><a href="/#" onClick={(e) => handle(e, count - 1)}>{count - 1}</a></li>,
                    <li className={`uk-border-circle ${(count === current) && "uk-active"}`} key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
                );
            } else if (count === 2) {
                result.push(
                    <li className={`uk-border-circle ${((count - 1) === current) && "uk-active"}`} key={count - 1}><a href="/#" onClick={(e) => handle(e, count - 1)}>{count - 1}</a></li>,
                    <li className={`uk-border-circle ${(count === current) && "uk-active"}`} key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
                );
            } else if (count === 3) {
                result.push(
                    <li className={`uk-border-circle ${((count - 2) === current) && "uk-active"}`} key={count - 2}><a href="/#" onClick={(e) => handle(e, count - 2)}>{count - 2}</a></li>,
                    <li className={`uk-border-circle ${((count - 1) === current) && "uk-active"}`} key={count - 1}><a href="/#" onClick={(e) => handle(e, count - 1)}>{count - 1}</a></li>,
                    <li className={`uk-border-circle ${(count === current) && "uk-active"}`} key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
                );
            }

        } else {

            if (current === 2 || current > 3) {
                result.push(<li key={1} className="uk-border-circle"><a href="/#" onClick={(e) => handle(e, 1)}>{1}</a></li>);
            }

            if (current > 4) {
                result.push(<li className="uk-border-circle uk-disabled" key={`disable`}><span>...</span></li>);
            }

            if (current > 2) {
                result.push(
                    <li className="uk-border-circle" key={current - 2}><a href="/#" onClick={(e) => handle(e, current - 2)}>{current - 2}</a></li>,
                    <li className="uk-border-circle" key={current - 1}><a href="/#" onClick={(e) => handle(e, current - 1)}>{current - 1}</a></li>,
                );
            }

            result.push(
                <li className="uk-border-circle uk-active" key={current}><a href="/#" onClick={(e) => handle(e, current)}>{current}</a></li>,
                <li className="uk-border-circle" key={current + 1}><a href="/#" onClick={(e) => handle(e, current + 1)}>{current + 1}</a></li>
            );

            if (current !== 4) {
                result.push(<li className="uk-border-circle" key={current + 2}><a href="/#" onClick={(e) => handle(e, current + 2)}>{current + 2}</a></li>);
            }

            if (current < 2) {
                result.push(
                    <li className="uk-border-circle" key={current + 3}><a href="/#" onClick={(e) => handle(e, current + 3)}>{current + 3}</a></li>,
                    <li className="uk-border-circle" key={current + 4}><a href="/#" onClick={(e) => handle(e, current + 4)}>{current + 4}</a></li>,
                );
            }

            if (current === 2) {
                result.push(<li className="uk-border-circle" key={current + 3}><a href="/#" onClick={(e) => handle(e, current + 3)}>{current + 3}</a></li>);
            }

            result.push(
                <li className="uk-border-circle uk-disabled" key={`disable-${current}`}><span>...</span></li>,
                <li className="uk-border-circle" key={count}><a href="/#" onClick={(e) => handle(e, count)}>{count}</a></li>
            );

        }
        return result;
    }

    handleRefreshSummary = () => {
        this.setState({ isSummaryLoaded: false });

        let survey_id = this.state.survey_id;

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'Accept': 'application/json' };

        axios.get(api + "/summary?survey_id=" + survey_id, { headers }).then(response => {
            console.log(response);
            this.setState({ summary: response.data.data, isSummaryLoaded: true, total_data: response.data.total_data });
        }).catch(error => {
            this.setState({ isSummaryLoaded: true });
            console.log(error);
            alert("Something is wrong!");
        });
    }

    render() {
        const { error, isInformationLoaded, isCodificationLoaded, isAnswerLoaded, items, codification, client, respondent, isRedirect, isRedirectDetail, option, isOptionLoaded, id, pagination, page, per_page, total_data, answer_status_id, tabs, isSummaryLoaded, summary, survey_id } = this.state;

        if (isRedirect) {
            return <Redirect to='/active' />;
        } else if (isRedirectDetail) {
            return <Redirect to={`/active/detail/${id}`} />;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isInformationLoaded || !isCodificationLoaded) {
                return <Loading />
            } else {
                return (
                    <div>
                        <ul className="detail-header uk-margin-remove-bottom" uk-tab="connect: .switcher-container">
                            <li id="tab-1" className={`tab uk-padding-remove ${(parseInt(tabs) === 1) ? `uk-active` : ``}`}><a href="/#" onClick={() => this.handleTabs(1)}>Information</a></li>
                            <li id="tab-2" className={`tab ${(parseInt(tabs) === 2) ? `uk-active` : ``}`}><a href="/#" onClick={() => this.handleTabs(2)}>Respondents</a></li>
                            <li id="tab-3" className={`tab ${(parseInt(tabs) === 3) ? `uk-active` : ``}`}><a href="/#" onClick={() => this.handleTabs(3)}>Summary</a></li>
                        </ul>

                        <ul className="uk-switcher switcher-container uk-margin-small">
                            {/* Information ----------------------------------------- */}
                            <li className={`tab tab-container ${(parseInt(tabs) === 1) ? `uk-active` : ``}`} id="tab-1-content">
                                {/* <Information surveyID={this.props.match.params.id} /> */}
                                <div>
                                    <div>
                                        <div className="uk-grid-small uk-flex-top" uk-grid="true">
                                            <div className="uk-width-1-5">
                                                <p>
                                                    <strong>Survey name</strong><br />
                                                    {items.name}
                                                </p>
                                            </div>
                                            <div className="uk-width-expand">
                                                <p>
                                                    <strong>Client name</strong><br />
                                                    {client.name}
                                                </p>
                                            </div>
                                            <div className="uk-width-auto uk-padding-small uk-padding-remove-vertical uk-padding-remove-right">
                                                <div className="uk-button-group">
                                                    <button type="button" uk-toggle="target: #modal-survey-edit" uk-tooltip="title: Edit; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Edit3 size={16} /></button>
                                                    <button type="button" onClick={() => this.handleArchive()} uk-tooltip="title: Archive; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Archive size={16} /></button>
                                                    <button type="button" onClick={() => this.handleDelete()} uk-tooltip="title: Delete; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Trash2 size={16} /></button>
                                                    <Link to={`/active/settings/${items.id}`} uk-tooltip="title: Role settings; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Settings size={16} /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-width-4-5">
                                        <p>
                                            <br/><strong>Description</strong><br />
                                            {items.description}
                                        </p>
                                        <hr/>
                                        <div className="uk-grid-small uk-child-width-1-4" uk-grid="true">
                                            <div>
                                                <p>
                                                    <strong>Created date</strong><br />
                                                    <span>{moment(items.created_at).format("dddd")}, {moment(items.created_at).format("D MMM")} {moment(items.created_at).format("YYYY")}</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    <strong>Last date</strong><br />
                                                    <span>{moment(items.updated_at).format("dddd")}, {moment(items.updated_at).format("D MMM")} {moment(items.updated_at).format("YYYY")}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                            {/* <div className="uk-width-auto">
                                                <div className="list-check-controller"><input className="uk-checkbox uk-border-circle" type="checkbox" /></div>
                                            </div> */}
                                            <div className="uk-width-expand">
                                                <p><strong>Codification</strong></p>
                                            </div>
                                            <div className="uk-width-auto uk-padding-small uk-padding-remove-vertical">
                                                <div className="uk-button-group">
                                                    <button onClick={() => this.handleBulkDelete('codifications')} uk-tooltip="title: Delete; pos: left" type="button" className="uk-button uk-button-default uk-button-small">
                                                        <Icon.Trash2 size={16} />
                                                    </button>
                                                    <a href="/#" uk-toggle="target: #modal-codification-clone" uk-tooltip="title: Clone; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center border-left"><Icon.Copy size={16} /></a>
                                                    <a href="/#" uk-toggle="target: #modal-codification-import" uk-tooltip="title: Import; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center border-left"><Icon.FilePlus size={16} /></a>
                                                    <a href="/#" uk-toggle="target: #modal-code" uk-tooltip="title: Create; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center border-left"><Icon.Plus size={16} /> New code</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="uk-position-relative empty-xframe">
                                        {(codification.length > 0) ?
                                        <ul className="uk-list uk-list-divider">
                                            {codification.map(translate => (
                                                <li id={`codificationsList-${translate.id}`} key={`codification-${translate.id}`}>
                                                    <div>
                                                        <div className="uk-grid-small uk-flex-top" uk-grid="true">
                                                            <div className="uk-width-auto bulk-items codifications">
                                                                <input className="uk-checkbox uk-border-circle" type="checkbox" name="bulkId" defaultValue={translate.id} onChange={this.handleBulkChange} />
                                                            </div>
                                                            <div className="uk-width-small">
                                                                <strong>{translate.code}</strong>
                                                            </div>
                                                            <div className="uk-width-expand">
                                                                <p className="uk-margin-remove">{translate.value}</p>
                                                                <div className="uk-button-group">
                                                                    {(translate.has_option === true) ?
                                                                        (
                                                                            <Fragment>
                                                                                <button onClick={() => this.viewOptions('modal-options', translate.id)} uk-toggle='target: #modal-options' type="button" className="uk-button uk-button-link"><small>Multi options</small></button>
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <small>Free answer</small>
                                                                            </Fragment>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            {(translate.identifier !== null) ? (
                                                                <div className="uk-width-auto">
                                                                    <p className="uk-margin-right uk-text-small"><strong className="uk-text-capitalize">As {translate.identifier.replace('_', ' ')}</strong></p>
                                                                </div>
                                                            ) : ""}
                                                            <div className="uk-width-auto">
                                                                <div className="uk-button-group">
                                                                    {/* <button type="button" uk-toggle="target: #modal-code-edit" onClick={() => this.handleEditCodification(translate.id)} uk-tooltip="title: Edit; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Edit3 size={16} /></button> */}
                                                                    <button type="button" uk-tooltip="title: Delete; pos: left" onClick={() => this.handleDeleteCodification(translate.id)} className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Trash2 size={16} /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        : <Empty /> }
                                    </div>
                                </div>
                            </li>

                            {/* Responden ----------------------------------------- */}
                            <li className={`tab tab-container ${(parseInt(tabs) === 2) ? `uk-active` : ``}`} id="tab-2-content">
                                <div>
                                    <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                        <div className="uk-width-expand">
                                            <p><strong>Respondents</strong></p>
                                        </div>
                                        <div className="uk-width-auto uk-padding-small uk-padding-remove-vertical">
                                            <div className="uk-button-group">
                                                {(answer_status_id === '1' && respondent.length > 0) &&
                                                    <button onClick={() => this.handleDeleteRAW()} uk-tooltip="title: Remove RAW data; pos: left" type="button" className="uk-button uk-button-default uk-button-small">
                                                        <Icon.Trash size={16} />
                                                    </button>
                                                }
                                                <button onClick={() => this.handleBulkDelete('answers')} uk-tooltip="title: Delete; pos: left" type="button" className={`uk-button uk-button-default ${(answer_status_id === '1' && respondent.length > 0) && 'border-left'} uk-button-small`}>
                                                    <Icon.Trash2 size={16} />
                                                </button>
                                                <button type="button" onClick={() => this.translate('true')} uk-tooltip="title: Translate; pos: left" className="uk-button uk-button-default uk-button-small border-left uk-text-center"><Icon.Framer size={16} /></button>
                                                <button onClick={() => this.handleDownload()} uk-tooltip="title: Download; pos: left" type="button" className="uk-button uk-button-small uk-button-default border-left"><Icon.Download size={16} /></button>
                                                <a href="/#" uk-toggle="target: #modal-import" uk-tooltip="title: Create; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center border-left uk-padding-remove-right"><Icon.Plus size={16} /> Import</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className="uk-position uk-position-fixed uk-position-small uk-position-bottom-right">
                                        <div className="uk-grid-small border-bottom uk-background-default uk-flex-middle" uk-grid="true">
                                            <div className="uk-width-auto uk-padding-remove">
                                                <select className="uk-select custom" value={answer_status_id} onChange={(e) => this.handleStatus(e)}>
                                                    <option value="1">RAW</option>
                                                    <option value="2">Audio check</option>
                                                    <option value="6">QC check</option>
                                                    <option value="5">Field check</option>
                                                    <option value="4">Final</option>
                                                    <option value="7">Drop out</option>
                                                    <option value="0">All Folder</option>
                                                </select>
                                            </div>
                                            <div className="uk-width-auto uk-padding-remove border-left">
                                                <select className="uk-select custom" value={per_page} onChange={(e) => this.handlePagging(e, "", "per_page")}>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">250</option>
                                                    <option value="500">500</option>
                                                    <option value="9999">All</option>
                                                </select>
                                            </div>
                                            <div className="uk-width-expand">
                                                <ul className="uk-pagination uk-margin-remove-bottom">{pagination}</ul>
                                            </div>
                                        </div>
                                    </div>

                                    {(isAnswerLoaded) ?
                                        (respondent.length > 0) ?
                                            <ul className="uk-list uk-list-divider">
                                                {respondent.map((tr, index) => (
                                                    <li id={`answersList-${tr.id}`} key={`tr-${tr.id}`}>
                                                        <div>
                                                            <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                                                <div className="uk-width-auto bulk-items answers">
                                                                    <input className="uk-checkbox uk-border-circle" type="checkbox" name="bulkId" defaultValue={tr.id} onChange={this.handleBulkChange} />
                                                                </div>
                                                                <div className="uk-width-auto uk-text-center">{parseInt(((page-1)*per_page))+index+1}</div>
                                                                <div className="uk-width-expand">
                                                                    {(tr.answer_status_id !== 1) ?
                                                                        <Link to={{
                                                                            pathname: `/active/respondent/${tr.id}/${survey_id}`,
                                                                            id: this.state.id
                                                                        }}>
                                                                            <p className="uk-margin-remove">
                                                                                {tr.decoded_data.map((td, index) => (
                                                                                    <Fragment key={`tds-${index}`}>
                                                                                        {(td.codification.identifier === "name") ? (
                                                                                            td.answers.map(data => (
                                                                                                <Fragment key={`span_name-${data.code}`}>{data.value}<br/></Fragment>
                                                                                            ))
                                                                                        ) : null }
                                                                                        {(td.codification.identifier === "interviewer_name") ? (
                                                                                            td.answers.map(data => (
                                                                                                <Fragment key={`span_name-${data.code}`}>Collected by {data.value}</Fragment>
                                                                                            ))
                                                                                        ) : null }
                                                                                    </Fragment>
                                                                                ))}
                                                                            </p>
                                                                        </Link>
                                                                    :
                                                                        <Fragment>
                                                                            <p className="uk-margin-remove">Need to be translated</p>
                                                                            <p className="uk-margin-remove uk-text-truncate">{(JSON.stringify(tr.decoded_data)).replace(/,/g, ' | ').replace(/"|{|}/g, '').replace(/:/g, ' : ')}</p>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                                <div className="uk-width-auto">
                                                                    <span className={`uk-width-small uk-text-center uk-label ${(tr.answer_status_id === 1) ? `uk-label-danger` : ``} ${(tr.answer_status_id === 2) ? `uk-label-warning` : ``} ${(tr.answer_status_id === 3) ? `uk-label-primary` : ``} ${(tr.answer_status_id === 4 || tr.answer_status_id === 7) ? `uk-label-muted` : ``} ${(tr.answer_status_id === 5) ? `uk-label-success` : ``} uk-border-pill uk-text-capitalize uk-padding-small uk-padding-remove-vertical`}>
                                                                        {(tr.answer_status_id === 1) ? "RAW" : ""}
                                                                        {(tr.answer_status_id === 2) ? "Audio check" : ""}
                                                                        {(tr.answer_status_id === 3) ? "Clean" : ""}
                                                                        {(tr.answer_status_id === 4) ? "Final" : ""}
                                                                        {(tr.answer_status_id === 5) ? "Field check" : ""}
                                                                        {(tr.answer_status_id === 6) ? "QC check" : ""}
                                                                        {(tr.answer_status_id === 7) ? "Drop out" : ""}
                                                                    </span>
                                                                </div>
                                                                <div className="uk-width-expand uk-text-right">
                                                                    <span className="uk-padding-small uk-padding-remove-vertical">{moment(tr.created_at).format("dddd")}, {moment(tr.created_at).format("D MMM")} {moment(tr.created_at).format("YYYY")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        : <div className="uuk-margin-large-top uk-width-1-1 uk-height-medium uk-inline"><Empty /></div>
                                    : <div className="uuk-margin-large-top uk-width-1-1 uk-height-medium uk-inline"><Loading /></div> }
                                </div>
                            </li>

                            {/* Summary ----------------------------------------- */}
                            <li className={`tab tab-container ${(parseInt(tabs) === 3) ? `uk-active` : ``}`} id="tab-3-content">
                                <div className="uk-grid-small" uk-grid="true">
                                    <div className="uk-width-1-1">
                                        <div className="">
                                            {(isSummaryLoaded) ?
                                                <BarChart
                                                    summary={summary}
                                                    title="Respondents by status"
                                                />
                                                : <p className="uk-margin-top uk-text-center">Loading...</p>
                                            }
                                        </div>
                                        <hr/>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <div className="uk-padding-small uk-background-primary red uk-light">
                                            <p>Total respondent</p>
                                            <h1>{total_data}</h1>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <div className="uk-padding-small uk-background-primary uk-light">
                                            <p>Total codification</p>
                                            <h1>{codification.length}</h1>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <div id="modal-options" className="uk-flex-top" uk-modal="true">
                            <div className="uk-modal-dialog uk-width-xlarge uk-margin-auto-vertical">
                            <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                <form className="uk-form-stacked" onSubmit={this.handleSubmitOption}>
                                    <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="true">
                                        <div className="uk-padding uk-padding-remove-bottom body-frame">
                                            <p>
                                                <strong>Options</strong>
                                            </p>
                                            <ul className="uk-list uk-list-divider uk-margin-bottom">
                                                {(isOptionLoaded) ?
                                                    option.map((item, index) => (
                                                        <li key={`survey-option-${index}`} className="uk-padding-small">
                                                            <div uk-grid="true">
                                                                <div className="uk-width-auto">
                                                                    {item.code}
                                                                </div>
                                                                <div className="uk-width-expand">
                                                                    {item.value}<br />
                                                                    {item.sub_value}
                                                                </div>
                                                                <div className="uk-width-auto">
                                                                    <button onClick={() => this.handleDeleteOption(item.id)} className="uk-button uk-button-small uk-button-default" type="button"><Icon.Trash2 size={16} /></button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                    : <li>Loading...</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="uk-modal-footer uk-text-right">
                                        <div className="uk-button-group rounded">
                                            <input name="code" value={this.state.code} onChange={this.handleChange} className="uk-input uk-width-small uk-border-rounded" placeholder="code" type="text" />
                                            <input name="value" value={this.state.value} onChange={this.handleChange} className="uk-input" type="text" placeholder="Option" />
                                            <input name="sub_value" value={this.state.sub_value} onChange={this.handleChange} className="uk-input" type="text" placeholder="Translated option" />
                                            <button className="uk-button uk-button-primary red uk-border-rounded" type="submit">add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <FormCodification survey_id={this.props.match.params.id} />
                        {/* <EditCodification survey_id={this.props.match.params.id} data={this.state.editCodification} /> */}
                        <FormAnswer survey_id={this.props.match.params.id} />
                        <ImportCodification survey_id={this.props.match.params.id} />
                        <CloneCodification survey_id={this.props.match.params.id} />
                        <Edit items={items} />

                        <div id="modal-delete" className="uk-flex-top" uk-modal="true">
                            <div className="uk-modal-dialog uk-margin-auto-vertical  uk-width-auto@m">
                            <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                                <div className="uk-modal-body uk-margin-small-top">
                                    Are sure want to delete this data?
                                </div>

                                <div className="uk-modal-footer uk-text-right">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">Close</button>
                                    <button className="uk-button uk-button-primary" type="button">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default detail
