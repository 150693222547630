import React, { Component } from 'react'
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom'
import $ from 'jquery';

class NavigationSide extends Component {

    constructor() {
        super()

        this.state = {
            activePage: "",
            lastPage: "",
            menuState: "expanded",
        }
    }

    componentDidMount(){
        let data = sessionStorage.getItem('localState');
        let obj = JSON.parse(data);
        if (obj) {
            this.setState(obj);
            this.activePage((obj.activePage !== null && obj.activePage !== undefined && obj.activePage !== "") ? obj.activePage : 'link-active');
        }
    }

    menuState = () => {
        this.setState({ menuState: (this.menuState === "expanded" ? "collapsed" : "expanded")});
    }

    activePage = (routeID) => {
        setTimeout(() => {
            var link = document.getElementsByClassName("link");
            Array.prototype.forEach.call(link, function (link, index) {
                link.setAttribute("class", "link");
            });

            this.setState({
                lastPage: this.state.activePage,
                activePage: routeID
            });

            let lastPage = this.state.lastPage;
            if(lastPage !== ""){
                var last = document.getElementById(lastPage);
                if (typeof (last) != 'undefined' && last != null) {
                    document.getElementById(lastPage).setAttribute("class", "link");
                }
            }

            var element = document.getElementById(routeID);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(routeID).setAttribute("class", "link active");
            }

            sessionStorage.setItem('localState', JSON.stringify(this.state));
        }, 100);
    }

    navToggle = () => {
        var x =  localStorage.getItem('cloveNav');
        console.log(x);
        setTimeout(function(){
            if ($(".navigation-side").hasClass('collapsed')) {
                localStorage.setItem('cloveNav', "collapsed");
            } else {
                localStorage.setItem('cloveNav', "expand");
            }
        }, 200);
    }

    render() {
        const navToggleState = localStorage.getItem('cloveNav');
        return (
            <div className={`navigation-side ${navToggleState}`}>
                <div className="navigation-side-toggle uk-padding-small uk-padding-remove-horizontal">
                    <div className="uk-grid-collapse" uk-grid="true">
                        <div className="uk-width-auto">
                            <button onClick={() => this.navToggle()} uk-toggle="target: .navigation-side, .container; cls: collapsed" type="button" className="button-menu uk-button uk-button-default uk-button-icon"><span className="icon" uk-icon="icon:menu; ratio:1"></span></button>
                        </div>
                        <div className="uk-width-expand">
                            <a href="/#" uk-toggle="target: #modal-survey"  className="button-add uk-button uk-button-default uk-preserve-width uk-padding-remove uk-border-rounded">
                                <div className="uk-grid-collapse frame uk-border-rounded" uk-grid="true">
                                    <div className="uk-width-auto icon">
                                        <i uk-icon="icon: plus"></i>
                                    </div>
                                    <div className="uk-width-expand label">
                                        <span className="navigation-side-label">New survey</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <ul className="uk-nav uk-nav-default">
                    <li className="uk-nav-header">Jobs</li>
                    <li id="link-active" className="link"><Link to="/active" onClick={() => this.activePage('link-active')}><Icon.Inbox className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Active</span></Link></li>
                    <li id="link-archived" className="link"><Link to="/archived" onClick={() => this.activePage('link-archived')}><Icon.Archive className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Archived</span></Link></li>
                    <li className="uk-nav-header">Folder</li>
                    <li id="link-audio-check" className="link"><Link to="/audio-check" onClick={() => this.activePage('link-audio-check')}><Icon.MicOff className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Audio check</span></Link></li>
                    <li id="link-qc-check" className="link"><Link to="/qc-check" onClick={() => this.activePage('link-qc-check')}><Icon.Sliders className="uk-margin-small-right" size={16} /><span className="navigation-side-label">QC check</span></Link></li>
                    <li id="link-field-check" className="link"><Link to="/field-check" onClick={() => this.activePage('link-field-check')}><Icon.CornerUpRight className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Field check</span></Link></li>
                    <li id="link-final" className="link"><Link to="/final" onClick={() => this.activePage('link-final')}><Icon.UserCheck className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Final</span></Link></li>
                    <li id="link-drop-out" className="link"><Link to="/drop-out" onClick={() => this.activePage('link-drop-out')}><Icon.Delete className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Drop out</span></Link></li>
                    <li className="uk-nav-header">Maintenance</li>
                    <li id="link-client" className="link"><Link to="/client" onClick={() => this.activePage('link-client')}><Icon.Flag className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Clients</span></Link></li>
                    <li id="link-respondent" className="link"><Link to="/respondent" onClick={() => this.activePage('link-respondent')}><Icon.Users className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Respondents</span></Link></li>
                    <li id="link-interviewer" className="link"><Link to="/interviewer" onClick={() => this.activePage('link-interviewer')}><Icon.User className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Interviewers</span></Link></li>
                    <li id="link-settings" className="link"><Link to="/settings" onClick={() => this.activePage('link-settings')}><Icon.Settings className="uk-margin-small-right" size={16} /><span className="navigation-side-label">Settings</span></Link></li>
                </ul>
            </div>
        )
    }
}

export default NavigationSide
