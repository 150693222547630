import React, { Component } from 'react'
import image from './none.svg'

class empty extends Component {
    render() {
        return (
            <div className="detail-header">
                <div className="empty-image">
                    <img src={image} className="large" alt="Empty" />
                    <p className="uk-text-lead">Select an item to view details</p>
                    <p className="uk-text-meta">None selected</p>
                </div>
            </div>
        )
    }
}

export default empty
