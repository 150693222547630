import React, { Component } from 'react'
import axios from 'axios';

class form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        // console.log('A form was submitted: ', this.state);
        const post = this.state;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.post(api + "/roles", post, { headers }).then(response => {
            window.location.reload(false);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });

        event.preventDefault();
    }

    render() {
        return (
            <div id="modal-add" className="uk-flex-top" uk-modal="true">
                <div className="uk-modal-dialog uk-width-medium uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                    <form className="uk-form-stacked" onSubmit={this.handleSubmit}>
                        <div className="uk-modal-body no-header uk-padding-remove" uk-overflow-auto="">
                            <div className="uk-padding uk-padding-remove-bottom body-frame">
                                <p>
                                    <strong>New role</strong><br/>
                                    Please fill in all required fields marked with (*)
                                </p>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-small">Name (*)</label>
                                    <input type="hidden" name="id" value="" />
                                    <input className="uk-input custom" placeholder="..." type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer">
                            <button className="uk-button uk-button-primary red uk-border-rounded uk-width-1-1" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default form
