import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import moment from 'moment';

const activeList = (routeID) => {
    setTimeout(() => {
        var list = document.getElementsByClassName("list");
        Array.prototype.forEach.call(list, function (list, index) {
            list.setAttribute("class", "list");
        });

        var element = document.getElementById(routeID);
        if (typeof (element) != 'undefined' && element != null) {
            document.getElementById(routeID).setAttribute("class", "list active");
        }
    }, 100);
}

class list extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        const items = this.props.items;
        return (
            <Fragment>
                { items.map((value) => (
                        <li id={`rolelist-${value.id}`} key={`rolelist-${value.id}`} className="list">
                            <Link to={{
                            pathname: `/settings/roles/detail/${value.id}`,
                            }} onClick={() => activeList(`rolelist-${value.id}`)}>
                                <div className="uk-grid-small uk-flex-top uk-padding-small uk-padding-remove-vertical " uk-grid="true">
                                    <div className="uk-width-expand">
                                        <span className="uk-float-right uk-text-small uk-text-right">{moment(value.created_at).format("D MMM")}, {moment(value.created_at).format("YYYY")}</span>
                                        <p>{value.name}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))
                }
            </Fragment>
        )
    }
}

export default list
