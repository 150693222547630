import React, { Component } from 'react';
import { Redirect } from 'react-router'
import axios from 'axios';
import Loading from '../../partials/loading';
import Error from '../../partials/error';
import * as Icon from 'react-feather';
import { Fragment } from 'react';
import $ from 'jquery';

class detail extends Component {
    constructor(props) {
        super(props)

        const id = this.props.match.params.id;
        this.state = {
            error: null,
            isRedirect: false,
            isLoaded: false,
            isSubmitting: false,
            isListLoaded: false,
            items:[],
            id: id,
            permissions: []
        }
    }

    handleDelete = () => {
        const id = this.state.id;
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        axios.delete(api + "/roles/ " + id, { headers }).then(response => {
            this.setState({ isRedirect: true });
            $("#rolelist-" + id).remove();
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    handleChange = (event) => {
        const target = event.target;
        var value = parseInt(target.value);
        var permissions = this.state.permissions;
        var index = permissions.indexOf(value);
        if (index > -1) {
            permissions.splice(index, 1);
        }

        if (target.checked) { permissions.push(value); }
        this.setState({ permissions: permissions });
    }

    handleSubmit = (event) => {
        this.setState({ isSubmitting: true });
        let permissions = this.state.permissions;
        permissions = permissions.filter((_, index) => permissions.hasOwnProperty(index))

        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const id = this.state.id;

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };


        let post = {
            role_id: id,
            permissions: permissions
        }

        axios.post(api + "/role-permissions", post,{ headers })
            .then(response => {
                this.setState({ isSubmitting: false });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
                this.setState({ isSubmitting: false, });
            });
        event.preventDefault();

    }

    componentDidMount(){
        const token = localStorage.getItem('cloveToken');
        const api = localStorage.getItem('cloveApi');
        const id = this.state.id;

        // list identifier
        let target = "rolelist-" + id;

        setTimeout(() => {
            var list = document.getElementsByClassName("list");
            Array.prototype.forEach.call(list, function (list, index) {
                list.setAttribute("class", "list");
            });

            var element = document.getElementById(target);
            if (typeof (element) != 'undefined' && element != null) {
                document.getElementById(target).setAttribute("class", "list active");
            }
        }, 100);

        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        axios.get(api + "/role-permissions?role_id=" + id, { headers })
        .then(response => {

            var arr = response.data.data;
            var x = [];
            arr.map(value => {
               return x.push(value.permission_id);
            });

            this.setState({ items: response.data.data, isLoaded: true, permissions: x });
            console.log('init', arr);
        }).catch(error => {
            let message = error.response.data.message;
            alert(message);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const id = this.props.match.params.id;
        if (prevState.id !== id) {
            this.setState({
                isLoaded: false,
                id: id
            });

            const token = localStorage.getItem('cloveToken');
            const api = localStorage.getItem('cloveApi');

            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };

            axios.get(api + "/role-permissions?role_id=" + id, { headers })
            .then(response => {
                var arr = response.data.data;
                var x = [];
                arr.map(value => {
                    return x.push(value.permission_id);
                });

                this.setState({ items: response.data.data, isLoaded: true, permissions: x });
            }).catch(error => {
                let message = error.response.data.message;
            alert(message);
            });
        }
    }

    render() {
        const { error, isLoaded, items, isRedirect, isSubmitting } = this.state;
        if (isRedirect) {
            return <Redirect to='/settings/roles' />;
        } else {
            if (error) {
                return <Error message={error.message} />
            } else if (!isLoaded) {
                return <Loading />
            } else {
                return (
                    <div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="detail-header uk-padding-remove-bottom uk-margin-small-bottom">
                                <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                    <div className="uk-width-expand">
                                        <h5>Information</h5>
                                    </div>
                                    <div className="uk-width-auto">
                                        <div className="uk-button-group rounded">
                                            <button type="button" onClick={() => this.handleDelete()} uk-tooltip="title: Delete; pos: left" className="uk-button uk-button-default uk-button-small uk-text-center"><Icon.Trash2 size={16} /></button>
                                            <button type="submit" className="uk-button uk-button-small uk-button-primary red uk-border-rounded">{(!isSubmitting) ? <Fragment><Icon.Check size={16} /> Save</Fragment> : <Fragment><span uk-spinner="ratio: 0.3"></span> Loading</Fragment>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul className="uk-list uk-list-divider">
                                    <li>ACCESS MANAGER</li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Roles</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="1" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 1) >= 0 ? true : false)} />View</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="2" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 2) >= 0 ? true : false)} />Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="3" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 3) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="4" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 4) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Users</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="5" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 5) >= 0 ? true : false)} />View</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="6" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 6) >= 0 ? true : false)} />Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="7" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 7) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="8" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 8) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="uk-list uk-list-divider uk-margin-medium-top">
                                    <li>SURVEY</li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Jobs</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="12" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 12) >= 0 ? true : false)} />Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="13" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 13) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="14" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 14) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Codefication</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="15" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 15) >= 0 ? true : false)} />Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="16" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 16) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="17" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 17) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Respondents</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="18" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 18) >= 0 ? true : false)} />Import</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="19" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 19) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="20" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 20) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Status</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="30" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 30) >= 0 ? true : false)} />QC check</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="29" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 29) >= 0 ? true : false)} />Field check</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="28" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 28) >= 0 ? true : false)} />Final</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="31" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 31) >= 0 ? true : false)} />Drop out</label></div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="uk-list uk-list-divider uk-margin-medium-top">
                                    <li>MAINTENANCE</li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Clients</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="9" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 9) >= 0 ? true : false)} />Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="10" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 10) >= 0 ? true : false)} />Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="11" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 11) >= 0 ? true : false)} />Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Respondents</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="21" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 21) >= 0 ? true : false)}/>Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="22" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 22) >= 0 ? true : false)}/>Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="23" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 23) >= 0 ? true : false)}/>Delete</label></div>
                                        </div>
                                    </li>
                                    <li>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand"><p className="uk-margin-remove"><strong>Interviewers</strong></p></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="24" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 24) >= 0 ? true : false)}/>Create</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="25" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 25) >= 0 ? true : false)}/>Update</label></div>
                                            <div><label><input className="uk-checkbox uk-border-circle uk-margin-small-right" type="checkbox" name="permissions" value="26" onChange={this.handleChange} defaultChecked={(items.findIndex(item => item.permission_id === 26) >= 0 ? true : false)}/>Delete</label></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                )
            }
        }
    }
}

export default detail
