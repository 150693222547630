import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import moment from 'moment';

const toInitials = str =>
    str
        .split(" ")
        .map(c => c.charAt(0).toUpperCase())
        .join("")
        .concat(str.charAt(1).toUpperCase())
        .substring(0, 2);

const activeList = (routeID) => {
    setTimeout(() => {
        var list = document.getElementsByClassName("list");
        Array.prototype.forEach.call(list, function (list, index) {
            list.setAttribute("class", "list");
        });

        var element = document.getElementById(routeID);
        if (typeof (element) != 'undefined' && element != null) {
            document.getElementById(routeID).setAttribute("class", "list active");
        }
    }, 100);
}

class list extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        const items = this.props.items;
        return (
            <Fragment>
                { items.map((value) => {
                    let id = value.id;
                    id = id.toString();

                    return (
                        <li id={`list-${value.id}`} key={`list-${value.id}`} className="list">
                            <Link to={{
                                pathname: `/settings/users/detail/${value.id}`,
                            }} onClick={() => activeList(`list-${value.id}`)}>
                                <div className="uk-grid-small uk-flex-top uk-padding-small uk-padding-remove-vertical " uk-grid="true">
                                    <div className="uk-width-auto">
                                        <span className={`initial-name bg-color-${(id.length > 1 ? id.substring(1, 3) : value.id)}`}>{toInitials(value.name)}</span>
                                    </div>
                                    <div className="uk-width-expand">
                                        <span className="uk-float-right uk-text-small uk-text-right">{moment(value.created_at).format("D MMM")}<br />{moment(value.created_at).format("YYYY")}</span>
                                        <p>{value.name}</p>
                                        <p className="uk-text-truncate">{value.address}</p>
                                        <p className="uk-text-truncate">{value.email}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </Fragment>
        )
    }
}

export default list
